
import { defineComponent } from "vue";
import { Area } from "@/interfaces/Area";
import {
  deleteArea,
  getArea,
  updateArea,
} from "@/services/cruzjiminiancj/AreaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "area-list",
  data() {
    return {
      cargando: false,
      area: {} as Area,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadArea(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getArea(id);
        this.area = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/areas/${this.area._id}`);
      } else {
        this.$router.push(`/areaslot/new`);
      }

      // this.$router.push(`/areas/${this.area._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateArea(this.$route.params.id, this.area);
          this.$router.push("/areas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteArea(this.$route.params.id);
          this.$router.push("/areas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadArea(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
