
import { defineComponent } from "vue";
import { Facturaa } from "@/interfaces/Facturaa";
import {
  deleteFacturaa,
  getFacturaa,
  updateFacturaa,
} from "@/services/cruzjiminiancj/FacturaaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "facturaa-list",
  data() {
    return {
      cargando: false,
      facturaa: {} as Facturaa,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadFacturaa(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getFacturaa(id);
        this.facturaa = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/facturaas/${this.facturaa._id}`);
      } else {
        this.$router.push(`/facturaaslot/new`);
      }

      // this.$router.push(`/facturaas/${this.facturaa._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateFacturaa(this.$route.params.id, this.facturaa);
          this.$router.push("/facturaas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteFacturaa(this.$route.params.id);
          this.$router.push("/facturaas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadFacturaa(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
