
const filters = ["Todos", "Error", "Ingreso", "Función"];

export default {
  props: ["filterIngresos", "search", "filteredIngresos"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },
};
