
import numeral from "numeral";
import moment from "moment";
import { updateOne } from "@/services/cruzjiminiancj/IngresoService";
import { Ingreso } from "@/interfaces/Ingreso";

export default {
  props: ["ingresos"],
  data() {
    return {
      data: false,
      totales: {
        ingresos: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    toColor(type: string) {
      if (type == "Error") {
        return "table-danger";
      } else if (type == "Función") {
        return "table-success";
      } else if (type == "Ingreso") {
        return "table-warning";
      }
    },
    valorTotal() {
      this.totales.ingresos = this.ingresos.length;
      this.totales.prefactura = this.ingresos.reduce(
        (accum: any, item: any) => accum + item.prefactura,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "d");
      moment.locale("es");
      return moment(out).calendar();
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
