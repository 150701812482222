import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

import GuestLayout from "../layouts/GuestLayout.vue";
import LogIn from "../views/LogInView.vue";
import SignUp from "../views/SignUpView.vue";

import DashboardLayout from "../layouts/DashboardLayout.vue";
import Dashboard from "../views/DashboardView.vue";

// Reportes
import ReporteByPer from "../views/ReporteByPer.vue";

// Usuario -------------------------------------------------------------------
import UsuarioComponent from "../components/Usuario/PrincipalComponent.vue";
import UsuarioDetailComponent from "../components/Usuario/DetailComponent.vue";
// ----------------------------------------------------------------------------

// Mejora -------------------------------------------------------------------
import MejoraComponent from "../components/Mejora/PrincipalComponent.vue";
import MejoraDetailComponent from "../components/Mejora/DetailComponent.vue";
import MejoraPrintComponent from "../components/Mejora/PrintComponent.vue";
import MejoraFormComp from "../components/Mejora/FormComp.vue";
// ----------------------------------------------------------------------------

// Factura -------------------------------------------------------------------
import FacturaComponent from "../components/Factura/PrincipalComponent.vue";
import FacturaDetailComponent from "../components/Factura/DetailComponent.vue";
import FacturaPrintComponent from "../components/Factura/PrintComponent.vue";
import FacturaFormComp from "../components/Factura/FormComp.vue";
// ----------------------------------------------------------------------------
// Facturaa -------------------------------------------------------------------
import FacturaaComponent from "../components/Facturaa/PrincipalComponent.vue";
import FacturaaDetailComponent from "../components/Facturaa/DetailComponent.vue";
import FacturaaPrintComponent from "../components/Facturaa/PrintComponent.vue";
import FacturaaFormComp from "../components/Facturaa/FormComp.vue";
// ----------------------------------------------------------------------------


// Medico -------------------------------------------------------------------
import MedicoComponent from "../components/Medico/PrincipalComponent.vue";
import MedicoDetailComponent from "../components/Medico/FormComp.vue";
import MedicoPrintComponent from "../components/Medico/PrintComponent.vue";
import MedicoFormComp from "../components/Medico/FormComp.vue";
// ----------------------------------------------------------------------------
// Consultorio -------------------------------------------------------------------
import ConsultorioComponent from "../components/Consultorio/PrincipalComponent.vue";
import ConsultorioDetailComponent from "../components/Consultorio/FormComp.vue";
import ConsultorioPrintComponent from "../components/Consultorio/PrintComponent.vue";
import ConsultorioFormComp from "../components/Consultorio/FormComp.vue";
// ----------------------------------------------------------------------------

// Empleado -------------------------------------------------------------------
import EmpleadoComponent from "../components/Empleado/PrincipalComponent.vue";
import EmpleadoDetailComponent from "../components/Empleado/FormComp.vue";
import EmpleadoPrintComponent from "../components/Empleado/PrintComponent.vue";
import EmpleadoFormComp from "../components/Empleado/FormComp.vue";
// ----------------------------------------------------------------------------
// Producto -------------------------------------------------------------------
import ProductoComponent from "../components/Producto/PrincipalComponent.vue";
import ProductoDetailComponent from "../components/Producto/FormComp.vue";
import ProductoPrintComponent from "../components/Producto/PrintComponent.vue";
import ProductoFormComp from "../components/Producto/FormComp.vue";
// ----------------------------------------------------------------------------

// Llamada -------------------------------------------------------------------
import LlamadaComponent from "../components/Llamada/PrincipalComponent.vue";
import LlamadaDetailComponent from "../components/Llamada/FormComp.vue";
import LlamadaPrintComponent from "../components/Llamada/PrintComponent.vue";
import LlamadaFormComp from "../components/Llamada/FormComp.vue";
// ----------------------------------------------------------------------------
// Cxc -------------------------------------------------------------------
import CxcComponent from "../components/Cxc/PrincipalComponent.vue";
import CxcDetailComponent from "../components/Cxc/FormComp.vue";
import CxcPrintComponent from "../components/Cxc/PrintComponent.vue";
import CxcFormComp from "../components/Cxc/FormComp.vue";
// ----------------------------------------------------------------------------

// Aseguradora -------------------------------------------------------------------
// import AseguradoraComponent from "../components/Aseguradora/PrincipalComponent.vue";
// import AseguradoraDetailComponent from "../components/Aseguradora/FormComp.vue";
// import AseguradoraPrintComponent from "../components/Aseguradora/PrintComponent.vue";
// import AseguradoraFormComp from "../components/Aseguradora/FormComp.vue";
// ----------------------------------------------------------------------------

// Reclamo -------------------------------------------------------------------

import ReclamoComponent from "../components/Reclamo/PrincipalComponent.vue";
import ReclamoDetailComponent from "../components/Reclamo/FormComp.vue";
import ReclamoPrintComponent from "../components/Reclamo/PrintComponent.vue";
import ReclamoFormComp from "../components/Reclamo/FormComp.vue";
// ----------------------------------------------------------------------------

// Especialista -------------------------------------------------------------------
import EspecialistaComponent from "../components/Especialista/PrincipalComponent.vue";
import EspecialistaDetailComponent from "../components/Especialista/FormComp.vue";
import EspecialistaPrintComponent from "../components/Especialista/PrintComponent.vue";
import EspecialistaFormComp from "../components/Especialista/FormComp.vue";
// ----------------------------------------------------------------------------

// Area -------------------------------------------------------------------
import AreaComponent from "../components/Area/PrincipalComponent.vue";
import AreaDetailComponent from "../components/Area/DetailComponent.vue";
import AreaPrintComponent from "../components/Area/PrintComponent.vue";
import AreaFormComp from "../components/Area/FormComp.vue";
// ----------------------------------------------------------------------------

// Reporte -------------------------------------------------------------------
import ReporteComponent from "../components/Reporte/PrincipalComponent.vue";
import ReporteDetailComponent from "../components/Reporte/DetailComponent.vue";
import ReportePrintComponent from "../components/Reporte/PrintComponent.vue";
import ReporteFormComp from "../components/Reporte/FormComp.vue";
// ----------------------------------------------------------------------------

// Reporte2 -------------------------------------------------------------------
import Reporte2Component from "../components/Reporte2/PrincipalComponent.vue";
import Reporte2DetailComponent from "../components/Reporte2/DetailComponent.vue";
import Reporte2PrintComponent from "../components/Reporte2/PrintComponent.vue";
import Reporte2FormComp from "../components/Reporte2/FormComp.vue";
// ----------------------------------------------------------------------------

// Ingreso -------------------------------------------------------------------
import IngresoAllComponent from "../components/Ingreso/PrincipalAllComponent.vue";
import IngresoComponent from "../components/Ingreso/PrincipalComponent.vue";
import IngresoDetailComponent from "../components/Ingreso/DetailComponent.vue";
import IngresoPrintComponent from "../components/Ingreso/PrintComponent.vue";
import IngresoFormComp from "../components/Ingreso/FormComp.vue";
// ----------------------------------------------------------------------------

// Chat -------------------------------------------------------------------
import ChatComponent from "../components/ChatComponent.vue";
// ----------------------------------------------------------------------------

const routes = [
  {
    path: "/",
    component: GuestLayout,
    children: [
      {
        path: "/",
        name: "LogIn",
        component: LogIn,
      },
      {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Encargado de Cuentas Médicas",
            "Facturador",
            "Médico Especialista",
            "Director Médico",
            "Recepcionista"
          ],
          
        },
      },

      // Usuarios --------------------------------------------------------------------
      {
        path: "/usuarios",
        name: "usuarios",
        component: UsuarioComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/usuarios/:id",
        name: "usuario-details",
        component: UsuarioDetailComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      // ----------------------------------------------------------------------------

      // Mejora --------------------------------------------------------------------
      {
        path: "/mejoras",
        name: "mejoras",
        component: MejoraComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/mejoras/:id",
        name: "mejora-details",
        component: MejoraDetailComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/mejoras2/:id",
        name: "mejora-details2",
        component: MejoraPrintComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/mejoras/new",
        name: "mejoras-form",
        component: MejoraFormComp,
        meta: {
          roles: ["Power User"],
        },
      },

      // ----------------------------------------------------------------------------

      // Factura --------------------------------------------------------------------
      {
        path: "/facturas",
        name: "facturas",
        component: FacturaComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/facturas/:id",
        name: "factura-details",
        component: FacturaDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/facturas2/:id",
        name: "factura-details2",
        component: FacturaPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado"],
        },
      },
      {
        path: "/facturas/new",
        name: "facturas-form",
        component: FacturaFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------
      // Facturaa --------------------------------------------------------------------
      {
        path: "/facturaas",
        name: "facturaas",
        component: FacturaaComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/facturaas/:id",
        name: "facturaa-details",
        component: FacturaaDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/facturaas2/:id",
        name: "facturaa-details2",
        component: FacturaaPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado"],
        },
      },
      {
        path: "/facturaas/new",
        name: "facturaas-form",
        component: FacturaaFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------

      // Medico --------------------------------------------------------------------
      {
        path: "/medicos",
        name: "medicos",
        component: MedicoComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente","Recepcionista","Director Médico"],
        },
      },
      {
        path: "/medicos/:id",
        name: "medico-details",
        component: MedicoDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente","Recepcionista","Director Médico"],
        },
      },
      {
        path: "/medicos2/:id",
        name: "medico-details2",
        component: MedicoPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado","Recepcionista","Director Médico"],
        },
      },
      {
        path: "/medicos/new",
        name: "medicos-form",
        component: MedicoFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente","Recepcionista","Director Médico"],
        },
      },

      // ----------------------------------------------------------------------------


      // Ingreso --------------------------------------------------------------------
      {
        path: "/ingresosAll",
        name: "ingresosAll",
        component: IngresoAllComponent,
        meta: {
          roles: ["Power User", "Administrador"],
        },
      },
      {
        path: "/ingresos",
        name: "ingresos",
        component: IngresoComponent,
        meta: {
          roles: ["Power User", "Administrador", "Médico Especialista"],
        },
      },
      {
        path: "/ingresos/:id",
        name: "ingreso-details",
        component: IngresoDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Médico Especialista"],
        },
      },
      {
        path: "/ingresos2/:id",
        name: "ingreso-details2",
        component: IngresoPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Médico Especialista"],
        },
      },
      {
        path: "/ingresos/new",
        name: "ingresos-form",
        component: IngresoFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Médico Especialista"],
        },
      },

      // ----------------------------------------------------------------------------
      
      // Consultorio --------------------------------------------------------------------
      {
        path: "/consultorios",
        name: "consultorios",
        component: ConsultorioComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/consultorios/:id",
        name: "consultorio-details",
        component: ConsultorioDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/consultorios2/:id",
        name: "consultorio-details2",
        component: ConsultorioPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/consultorios/new",
        name: "consultorios-form",
        component: ConsultorioFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------
      // Empleado --------------------------------------------------------------------
      {
        path: "/empleados",
        name: "empleados",
        component: EmpleadoComponent,
        meta: {
          roles: ["Power User", "Agente"],
        },
      },
      {
        path: "/empleados/:id",
        name: "empleado-details",
        component: EmpleadoDetailComponent,
        meta: {
          roles: ["Power User", "Agente"],
        },
      },
      {
        path: "/empleados2/:id",
        name: "empleado-details2",
        component: EmpleadoPrintComponent,
        meta: {
          roles: ["Power User", "Agente"],
        },
      },
      {
        path: "/empleados/new",
        name: "empleados-form",
        component: EmpleadoFormComp,
        meta: {
          roles: ["Power User", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------
      // Producto --------------------------------------------------------------------
      {
        path: "/productos",
        name: "productos",
        component: ProductoComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/productos/:id",
        name: "producto-details",
        component: ProductoDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/productos2/:id",
        name: "producto-details2",
        component: ProductoPrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado"],
        },
      },
      {
        path: "/productos/new",
        name: "productos-form",
        component: ProductoFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------
      // Llamada --------------------------------------------------------------------
      {
        path: "/llamadas",
        name: "llamadas",
        component: LlamadaComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
            "Médico Especialista",
            "Recepcionista",
            "Director Médico"
          ],
        },
      },
      {
        path: "/llamadas/:id",
        name: "llamada-details",
        component: LlamadaDetailComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
            "Médico Especialista",
            "Recepcionista",
            "Director Médico"
          ],
        },
      },
      {
        path: "/llamadas2/:id",
        name: "llamada-details2",
        component: LlamadaPrintComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
            "Médico Especialista",
            "Recepcionista",
            "Director Médico"
          ],
        },
      },
      {
        path: "/llamadas/new",
        name: "llamadas-form",
        component: LlamadaFormComp,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
            "Médico Especialista",
            "Recepcionista",
            "Director Médico"
          ],
        },
      },

      // ----------------------------------------------------------------------------
      // Cxc --------------------------------------------------------------------
      {
        path: "/cxcs",
        name: "cxcs",
        component: CxcComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
          ],
        },
      },
      {
        path: "/cxcs/:id",
        name: "cxc-details",
        component: CxcDetailComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
          ],
        },
      },
      {
        path: "/cxcs2/:id",
        name: "cxc-details2",
        component: CxcPrintComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
          ],
        },
      },
      {
        path: "/cxcs/new",
        name: "cxcs-form",
        component: CxcFormComp,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Facturador",
          ],
        },
      },

      // ----------------------------------------------------------------------------
      // Aseguradora --------------------------------------------------------------------
      // {
      //   path: "/aseguradoras",
      //   name: "aseguradoras",
      //   component: AseguradoraComponent,
      //   meta: {
      //     roles: ["Power User", "Encargado de Cuentas Médicas"],
      //   },
      // },
      // {
      //   path: "/aseguradoras/:id",
      //   name: "aseguradora-details",
      //   component: AseguradoraDetailComponent,
      //   meta: {
      //     roles: ["Power User", "Encargado de Cuentas Médicas"],
      //   },
      // },
      // {
      //   path: "/aseguradoras2/:id",
      //   name: "aseguradora-details2",
      //   component: AseguradoraPrintComponent,
      //   meta: {
      //     roles: ["Power User", "Encargado de Cuentas Médicas"],
      //   },
      // },
      // {
      //   path: "/aseguradoras/new",
      //   name: "aseguradoras-form",
      //   component: AseguradoraFormComp,
      //   meta: {
      //     roles: ["Power User", "Encargado de Cuentas Médicas"],
      //   },
      // },

      // ----------------------------------------------------------------------------
      // Reclamo --------------------------------------------------------------------
      {
        path: "/reclamos",
        name: "reclamos",
        component: ReclamoComponent,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas"],
        },
      },
      {
        path: "/reclamos/:id",
        name: "reclamo-details",
        component: ReclamoDetailComponent,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas"],
        },
      },
      {
        path: "/reclamos2/:id",
        name: "reclamo-details2",
        component: ReclamoPrintComponent,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas"],
        },
      },
      {
        path: "/reclamos/new",
        name: "reclamos-form",
        component: ReclamoFormComp,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas"],
        },
      },

      // ----------------------------------------------------------------------------
      // Especialista --------------------------------------------------------------------
      {
        path: "/especialistas",
        name: "especialistas",
        component: EspecialistaComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Engardo de Reclamacione Médicas",
            "Director Médico",
          ],
        },
      },
      {
        path: "/especialistas/:id",
        name: "especialista-details",
        component: EspecialistaDetailComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Engardo de Reclamacione Médicas",
            "Director Médico"
          ],
        },
      },
      {
        path: "/especialistas2/:id",
        name: "especialista-details2",
        component: EspecialistaPrintComponent,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Director Médico"
          ],
        },
      },
      {
        path: "/especialistas/new",
        name: "especialistas-form",
        component: EspecialistaFormComp,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Director Médico"
          ],
        },
      },

      // ----------------------------------------------------------------------------

      // Area --------------------------------------------------------------------
      {
        path: "/areas",
        name: "areas",
        component: AreaComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/areas/:id",
        name: "area-details",
        component: AreaDetailComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/areas2/:id",
        name: "area-details2",
        component: AreaPrintComponent,
        meta: {
          roles: ["Power User"],
        },
      },
      {
        path: "/areas/new",
        name: "areas-form",
        component: AreaFormComp,
        meta: {
          roles: ["Power User"],
        },
      },

      // ----------------------------------------------------------------------------

      // chat
      {
        path: "/chat",
        name: "chat",
        component: ChatComponent,
        meta: {
          roles: ["Power User"],
        },
      },

      // Reporte --------------------------------------------------------------------
      {
        path: "/reportes",
        name: "reportes",
        component: ReporteComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reportes/:id",
        name: "reporte-details",
        component: ReporteDetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reportes2/:id",
        name: "reporte-details2",
        component: ReportePrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reportes/new",
        name: "reportes-form",
        component: ReporteFormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------

      // Reporte2 --------------------------------------------------------------------
      {
        path: "/reporte2s",
        name: "reporte2s",
        component: Reporte2Component,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reporte2s/:id",
        name: "reporte2-details",
        component: Reporte2DetailComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reporte2s2/:id",
        name: "reporte2-details2",
        component: Reporte2PrintComponent,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },
      {
        path: "/reporte2s/new",
        name: "reporte2s-form",
        component: Reporte2FormComp,
        meta: {
          roles: ["Power User", "Administrador", "Encargado", "Agente"],
        },
      },

      // ----------------------------------------------------------------------------

      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          roles: [
            "Power User",
            "Administrador",
            "Encargado",
            "Agente",
            "Especialista",
            "Encargado de Cuentas Médicas",
            "Facturador",
            "Médico Especialista",
            "Recepcionista",
            "Director Médico"
          ],
        },
      },

      // Reportes
      {
        path: "/porperiodo",
        name: "ReporteByPer",
        component: ReporteByPer,
        meta: {
          roles: ["Power User", "Administrador"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta?.requiresAuth && store.state.isAuthenticated) {
    // let users enter if authenticated
    if (to.meta?.roles?.includes(store.state.user.type)) {
      next();
    } else {
      // console.log('User is not authorized for route.')
      alert("Usuario no Autorizado.");
    }
  } else if (to.meta?.requiresAuth) {
    // otherwise, route them to /login
    next("/");
  } else {
    next();
  }
});

export default router;
