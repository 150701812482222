
import Filters from "@/components/Reporte/FiltersComponent.vue";
import ListadoComponent from "@/components/Reporte/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Reporte } from "@/interfaces/Reporte";
import { getReportes, getReportesByUser } from "@/services/cruzjiminiancj/ReporteService";
import { Usuario } from "@/interfaces/Usuario";
import Pusher from "pusher-js";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";

export default {
  name: "App",

  components: {
    // Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      usuario: {} as Usuario,
      cargando: false,
      reportes: [] as Reporte[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async filterReportes(catName: string) {
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.reportes = this.reportes.filter((reporte: Reporte) => {
          return reporte.type === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.reportes = this.reportes.filter((reporte: Reporte) => {
          return reporte.description.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadReportes() {
      this.toggleLoading();
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadReportesByUser() {
      this.toggleLoading();
      try {
        const res = await getReportesByUser(this.usuario);
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadReportesByUser2() {
      try {
        const res = await getReportesByUser(this.usuario);
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadReportes2() {
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("f2c972e55a9c9eac5f9a", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadReportesByUser2();
      });
      // End pusher subscribe
    },
  },

  mounted() {
    this.usuario.nombre = this.$store.state.user.usuario;
    // this.loadReportes();
    this.loadReportesByUser();
    this.pusherSubscribe();
  },
};
