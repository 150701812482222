
const filters = [
  "Todos",
  "1 - Recibido por Auditoría Interna",
  "2 - Verificado por Auditoría Interna",
  "3 - Verificado por Auditoría Externa",
  "4 - Recibido por Reclamaciones Médicas",
  "5 - Verificado por Reclamaciones Médicas",
  "6 - Cargado a Lote",
  "7 - Enviado a la Aseguradora",
];

export default {
  props: ["filterEspecialistas", "search", "filteredEspecialistas"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },

  methods: {
    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },
  },
};
