
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Factura } from "@/interfaces/Factura";
import {
  createFactura,
  eliminateFacturas,
  createFacturaa,
  getOneFactura,
} from "@/services/cruzjiminiancj/FacturaService";

import {
  APIgetFactura,
  getFacturas,
} from "@/services/datasamicj/FacturaService";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "facturas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      factura: {} as Factura,
      oneFactura: {} as Factura,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.factura.no = 1;
    // this.defFields();
    this.focus();
  },

  methods: {
    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    fixTime() {
      this.factura.fecha = this.formatDateToFix(
        this.factura.fecha,
        true
      );
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    async getFactura() {
      this.toggleLoading();
      this.documento.idfact = this.factura.idfact;
      if (this.documento) {
        try {
          const res = await APIgetFactura(this.documento);
          // const res = await getFacturas();
          // this.factura = res.data;
          // Asignar Campos Seleccionandolos
          this.factura.idfact = res.data.idfact;
          this.factura.id_ars = res.data.id_ars;
          this.factura.nom = res.data.nom;
          this.factura.nro_autorizacion_salida =
            res.data.nro_autorizacion_salida;
          this.factura.fecha = res.data.fecha;
          this.factura.numero_afiliado = res.data.numero_afiliado;
          this.factura.rnc = res.data.rnc;
          this.factura.tipo_factura = res.data.tipo_factura;
          this.factura.cobertura = res.data.cobertura;
          this.factura.total_empleado = res.data.total_empleado;

          this.factura.status = this.$store.state.user.defaultStatus;
          this.factura.actividad = [];
          this.factura.actividad.push({
            description: this.$store.state.user.defaultStatus,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });

          this.fixTime();
          this.error = "";
        } catch (error) {
          // console.error(error);
        }
      }
      await this.toggleLoading();
      if (this.factura.rnc == "") {
        this.factura.rnc = "-";
      }
      this.focus();
    },

    isError(message: string) {
      if (message == "Factura Registrada Exitosamente") {
        return "success";
      } else if (message == "Ya Existe una Factura Registrada con este Id") {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcFactura() {
      (this.factura.retension = this.factura.bruto * 0.1),
        (this.factura.neto = this.factura.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    // defFields() {
    // this.factura.status = this.$store.state.user.defaultStatus;
    // this.actividad = "4 - Recibido por Reclamaciones Médicas";
    // this.factura.actividad.push(this.actividad);
    // },

    async loadOneFactura() {
      try {
        const res = await getOneFactura();
        this.oneFactura = res.data;
        this.one = this.oneFactura[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.factura.no = this.nextNo;
        this.factura.principal = this.nextNo;
        this.factura.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveFacturaa() {
      await this.loadOneFactura();
      try {
        const res = await createFacturaa(this.empleado);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFactura() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneFactura();
          this.oneFactura = res.data;
          this.one = this.oneFactura[0];
          this.nextNo = this.one.no + 1;
          this.factura.no = this.nextNo;
          this.factura.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.factura.status = this.$store.state.user.defaultStatus;
        this.factura.userReg = this.$store.state.user.usuario;
        const res = await createFactura(this.factura).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/facturas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      // await this.defFields();
      document.getElementById("idfact").focus();
      this.toggleAlert();
    },

    async deleteAllFacturas() {
      try {
        const res = await eliminateFacturas(this.factura);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      // this.factura.no = "";
      this.factura.idfact = "";
      this.factura.id_ars = "";
      this.factura.nom = "";
      this.factura.nro_autorizacion_salida = "";
      this.factura.fecha = "";
      this.factura.numero_afiliado = "";
      this.factura.rnc = "";
      this.factura.tipo_factura = "";
      this.factura.cobertura = "";
      this.factura.total_empleado = "";
      // this.factura.status = "";
      // this.factura.actividad = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("idfact").focus();
    },
  },
});
