
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Medico } from "@/interfaces/Medico";
// import { Funcion } from "@/interfaces/Funcion";
// import { Vitola } from "@/interfaces/Vitola";
// import { Empleado } from "@/interfaces/Empleado";
// import {
//   getPeg,
//   getMez,
//   getEmp,
// } from "@/services/empleadoscj/Empleado";
// import { getLigas } from "@/services/cruzjiminiancj/Liga";
import {
  createMedico,
  eliminateMedicos,
  createMedicoa,
  getOneMedico,
  deleteMedico,
  getMedico,
  updateMedico,
} from "@/services/cruzjiminiancj/Medico";
// import { getVitolas } from "@/services/cruzjiminiancj/Vitola";
// import { getFuncions } from "@/services/cruzjiminiancj/Funcion";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "medicos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // medicos: [] as Empleado[],
      // medicosEmp: [] as Empleado[],
      // medicosPeg: [] as Empleado[],
      // medicosMez: [] as Empleado[],
      campoFocus: "edificio",
      mensageError: "Error",
      mensageExito: "Medico Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Medico?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      medico: {} as Medico,
      oneMedico: {} as Medico,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/medicos/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Médico";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Médico";
    }

    if (this.modoForm == "add") {
      this.medico.no = 1;
      this.medico.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadMedico(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    setSeguro(seguro: string) {
      this.medico.seguro = seguro;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("ubicacion").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("ubicacion").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.medico.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.medico.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("currentConsulta").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.medico.edad = years;
    },

    // async filterEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.medicosPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.medicosEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.medicosMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTime() {
      this.medico.fecha = this.formatDateToFix(this.medico.fecha, true);
      this.medico.fechaActual = this.formatDateToFix(
        this.medico.fechaActual,
        true
      );
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadMedico(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getMedico(id);
        this.medico = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.medico.userMod = this.$store.state.user.usuario;
          await updateMedico(this.$route.params.id, this.medico);
          this.addMessage();
          this.$router.push("/medicos");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteMedico(this.$route.params.id);
            this.addMessage();
            this.$router.push("/medicos");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcMedico() {
      (this.medico.retension = this.medico.bruto * 0.1),
        (this.medico.neto = this.medico.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.medico.fechaActual = new Date();
      this.medico.fecha = new Date();
    },

    async loadOneMedico() {
      try {
        const res = await getOneMedico();
        this.oneMedico = res.data;
        this.one = this.oneMedico[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.medico.no = this.nextNo;
        this.medico.principal = this.nextNo;
        this.medico.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveMedicoa() {
      await this.loadOneMedico();
      try {
        const res = await createMedicoa(this.empleado);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveMedico() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneMedico();
          this.oneMedico = res.data;
          this.one = this.oneMedico[0];
          this.nextNo = this.one.no + 1;
          this.medico.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.medico.userReg = this.$store.state.user.usuario;
        const res = await createMedico(this.medico).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/medicos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllMedicos() {
      try {
        const res = await eliminateMedicos(this.medico);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.medico.edificio = "";
      this.medico.consultorio = "";
      this.medico.medico = "";
      this.medico.especialidad = "";
      this.medico.diasConsulta = "";
      this.medico.horario = "";
      this.medico.telefono = "";
      this.medico.secretaria = "";
      this.medico.precios = "";
      this.medico.telSecretaria = "";
      this.medico.telConsultorio = "";
      this.medico.procedimientos = "";
      this.medico.preciosPrivado = "";
      this.medico.preciosDiferencia = "";
      this.medico.preciosProcedimientos = "";
      this.medico.notas = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
