
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Producto } from "@/interfaces/Producto";
// import { Funcion } from "@/interfaces/Funcion";
// import { Vitola } from "@/interfaces/Vitola";
// import { Producto } from "@/interfaces/Producto";
// import {
//   getPeg,
//   getMez,
//   getEmp,
// } from "@/services/cruzjiminiancj/Producto";
// import { getLigas } from "@/services/cruzjiminiancj/Liga";
import {
  createProducto,
  eliminateProductos,
  createProductoa,
  getOneProducto,
  deleteProducto,
  getProducto,
  updateProducto,
} from "@/services/cruzjiminiancj/Producto";
// import { getVitolas } from "@/services/cruzjiminiancj/Vitola";
// import { getFuncions } from "@/services/cruzjiminiancj/Funcion";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import { getProductos } from "@/services/cruzjiminiancj/Producto";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "productos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      productos: [] as Producto[],
      subs: [],
      preSubs: [],
      almacenes: [
        {
          desc: "Almacén No. 01 (General)",
          subs: ["Donaciones", "Venta / Servicios", "Muestras Médicas"],
        },
        {
          desc: "Almacén No. 02 (Suministro de Oficina y Limpieza)",
          subs: [
            "Sub-01 (Materiales de Oficina)",
            "Sub-02 (Materiales de Limpieza)",
          ],
        },
        {
          desc: "Almacén No. 03 (Abastecimiento)",
          subs: [
            "Sub-01 (Carro de Paro UCI)",
            "Sub-02 (Carro de Paro 2do Piso)",
            "Sub-03 (Carro de Paro Perinato)",
            "Sub-04 (Carro de Paro Quirófano)",
            "Sub-05 (Carro de Paro 3er Piso)",
          ],
        },
        {
          desc: "Almacén No. 04 (Emergencia, Quirófano y Ambulatorio)",
          subs: [
            "Sub-01 (Emergencia)",
            "Sub-02 (Depósito Quirófano)",
            "Sub-03 (Imágenes)",
            "Sub-04 (Laboratorio Clínico)",
            "Sub-05 (Laboratorio Biomolecular)",
            "Sub-06 (Farmacia 3er Piso)",
            "Sub-07 (Emergencia Pediatrica)",
          ],
        },
        {
          desc: "Almacén No. 70 (Cocina)",
          subs: [
            "Sub-01 (Alimentos)",
            "Sub-02 (Bebidas y Líquidos)",
            "Sub-03 (Suministros)",
            "Sub-04 (Utencilios)",
          ],
        },
        {
          desc: "Almacén No. 98 (Vencidos)",
          subs: [],
        },
        {
          desc: "Almacén No. 99 (Averiado)",
          subs: [],
        },
      ],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // productos: [] as Producto[],
      // productosEmp: [] as Producto[],
      // productosPeg: [] as Producto[],
      // productosMez: [] as Producto[],
      campoFocus: "barcode",
      mensageError: "Error",
      mensageExito: "Producto Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Producto?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      conectando: false,
      producto: {} as Producto,
      oneProducto: {} as Producto,
      one: {},
      nextNo: Number,
      productoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    this.fechaActual = new Date();
    // this.loadProductos();
    // this.seguros = [
    //   "Victor Osiris Alcántara Castro",
    //   "Abel David Ventura Rivas",
    // ];
    if (this.$route.fullPath == "/productos/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Producto";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Producto";
    }

    if (this.modoForm == "add") {
      this.producto.no = 1;
      this.producto.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadProducto(this.$route.params.id);
        this.defSubs();

        // this.producto.finVac = new Date();
        // this.producto.fechaNac = new Date();
        // this.producto.fechaCont = new Date();
      }
      this.fixTime();
    }

    this.focus();
    // this.loadProductos();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterProductos();
  },

  methods: {
    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/productos/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Producto";
      this.showDeleteMethod();
      await this.loadProducto(id);
      this.defSubs();

      // this.producto.finVac = new Date();
      // this.producto.fechaNac = new Date();
      // this.producto.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();
      try {
        const res = await getProductos();
        this.productos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (this.producto.barcode !== "Todos") {
        this.productos = this.productos.filter((producto: Producto) => {
          return producto.barcode
            .toLowerCase()
            .includes(this.producto.barcode.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("division").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.producto.mesVenc = moment(date).format("MM");
      this.producto.anoVenc = moment(date).format("YYYY");
    },

    defSubs() {
      this.preSubs = this.almacenes.filter((almacen: Producto) => {
        return almacen.desc
          .toLowerCase()
          .includes(this.producto.almacn.toLowerCase());
      });
      this.subs = this.preSubs[0].subs;
    },

    setSeguro(seguro: string, seguro2: string) {
      this.producto.supervisor = seguro + " " + seguro2;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("cod").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("cod").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.producto.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.producto.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.producto.edad = years;
    },

    // async filterProductos() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.productosPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.productosEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.productosMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    async loadProductos() {
      this.toggleLoading();
      try {
        const res = await getProductos();
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    fixTime() {
      if (this.producto.vence) {
        this.producto.vence = this.formatDateToFix(this.producto.vence, false);
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(4, "hours");
        return moment(out).format("yyyy-MM");
      }
    },

    async loadProducto(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getProducto(id);
        this.producto = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.producto.userMod = this.$store.state.user.usuario;
          await updateProducto(this.$route.params.id, this.producto);
          this.addMessage();
          this.$router.push("/productos");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteProducto(this.$route.params.id);
            this.addMessage();
            this.$router.push("/productos");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcProducto() {
      (this.producto.retension = this.producto.bruto * 0.1),
        (this.producto.neto = this.producto.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("MM");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.producto.fecha = new Date();
      // this.producto.fechaNac = new Date();
      // this.producto.fechaCont = new Date();
    },

    async loadOneProducto() {
      try {
        const res = await getOneProducto();
        this.oneProducto = res.data;
        this.one = this.oneProducto[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.producto.no = this.nextNo;
        this.producto.principal = this.nextNo;
        this.producto.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveProductoa() {
      await this.loadOneProducto();
      try {
        const res = await createProductoa(this.producto);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveProducto() {
      this.toggleLoading();
      this.toggleConectando();
      try {
        try {
          const res = await getOneProducto();
          this.oneProducto = res.data;
          this.one = this.oneProducto[0];
          this.nextNo = this.one.no + 1;
          this.producto.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.producto.userReg = this.$store.state.user.usuario;
        // this.error = "Estableciendo Conexión";
        const res = await createProducto(this.producto).then(
          (res) => {
            this.toggleConectando();
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
            this.toggleConectando();
          }
        );
        // this.$router.push("/productos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllProductos() {
      try {
        const res = await eliminateProductos(this.producto);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.producto.fecha = "";
      this.producto.lineaGrupo = "";
      this.producto.codigo = "";
      this.producto.ref = "";
      this.producto.vence = "";
      this.producto.mesVenc = "";
      this.producto.anoVenc = "";
      this.producto.lote = "";
      this.producto.barcode = "";
      this.producto.farmaco = "";
      this.producto.regInd = "";
      this.producto.regSan = "";
      this.producto.precVenta = "";
      this.producto.precCaja = "";
      this.producto.itbis = "";
      this.producto.costoUnit = "";
      this.producto.costCaja = "";
      this.producto.solPor = "";
      this.producto.autPor = "";
      this.producto.cant = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    toggleConectando() {
      this.conectando = !this.conectando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
