
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Especialista } from "@/interfaces/Especialista";
import {
  createEspecialista,
  eliminateEspecialistas,
  createEspecialistaa,
  getOneEspecialista,
  deleteEspecialista,
  getEspecialista,
  updateEspecialista,
} from "@/services/cruzjiminiancj/Especialista";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import { getEspecialistas } from "@/services/cruzjiminiancj/Especialista";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "especialistas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      especialistas: [] as Especialista[],
      subs: [],
      preSubs: [],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      currentEdition: {} as any,
      campoFocus: "nombre",
      mensageError: "Error",
      mensageExito: "Especialista Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Especialista?",
      encabezado: "",
      modoForm: "",
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      especialista: {} as Especialista,
      oneEspecialista: {} as Especialista,
      one: {},
      nextNo: Number,
      especialistaSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    this.fechaActual = new Date();
    if (this.$route.fullPath == "/especialistas/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Especialista";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Especialista";
    }

    if (this.modoForm == "add") {
      this.especialista.no = 1;
      this.especialista.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadEspecialista(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
  },

  methods: {
    async redirect(id: string) {
      this.$router.push(`/especialistas/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Especialista";
      this.showDeleteMethod();
      await this.loadEspecialista(id);
      this.defSubs();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (this.especialista.barcode !== "Todos") {
        this.especialistas = this.especialistas.filter(
          (especialista: Especialista) => {
            return especialista.nombre
              .toLowerCase()
              .includes(this.especialista.barcode.toLowerCase());
          }
        );
      }
      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("division").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.especialista.mesVenc = moment(date).format("MM");
      this.especialista.anoVenc = moment(date).format("YYYY");
    },

    setSeguro(seguro: string, seguro2: string) {
      this.especialista.supervisor = seguro + " " + seguro2;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("cod").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("cod").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.especialista.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.especialista.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.especialista.edad = years;
    },

    // async filterEspecialistas() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.especialistasPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.especialistasEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.especialistasMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    async loadEspecialistas() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    fixTime() {
      if (this.especialista.fecha) {
        this.especialista.fecha = this.formatDateToFix(
          this.especialista.fecha,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(4, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadEspecialista(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getEspecialista(id);
        this.especialista = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.especialista.userMod = this.$store.state.user.usuario;
          await updateEspecialista(this.$route.params.id, this.especialista);
          this.addMessage();
          this.$router.push("/especialistas");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteEspecialista(this.$route.params.id);
            this.addMessage();
            this.$router.push("/especialistas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcEspecialista() {
      (this.especialista.retension = this.especialista.bruto * 0.1),
        (this.especialista.neto = this.especialista.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("MM");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.especialista.fecha = new Date();
      // this.especialista.fechaNac = new Date();
      // this.especialista.fechaCont = new Date();
    },

    async loadOneEspecialista() {
      try {
        const res = await getOneEspecialista();
        this.oneEspecialista = res.data;
        this.one = this.oneEspecialista[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.especialista.no = this.nextNo;
        this.especialista.principal = this.nextNo;
        this.especialista.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveEspecialistaa() {
      await this.loadOneEspecialista();
      try {
        const res = await createEspecialistaa(this.especialista);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveEspecialista() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneEspecialista();
          this.oneEspecialista = res.data;
          this.one = this.oneEspecialista[0];
          this.nextNo = this.one.no + 1;
          this.especialista.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.especialista.userReg = this.$store.state.user.usuario;
        const res = await createEspecialista(this.especialista).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/especialistas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllEspecialistas() {
      try {
        const res = await eliminateEspecialistas(this.especialista);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.especialista.nombre = "";
      this.especialista.telefonos = "";
      this.especialista.orden = "";
      this.especialista.especialidad = "";
      this.especialista.tipoHorario = "";
      this.especialista.horario = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
