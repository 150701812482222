
import { defineComponent } from "vue";
import { Cxc } from "@/interfaces/Cxc";
// import {
//   deleteCxc,
//   getCxc,
//   updateCxc,
// } from "@/services/datasamicj/FacturaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "cxc-list",
  data() {
    return {
      cargando: false,
      cxc: {} as Cxc,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    // async loadCxc(id: string) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await getCxc(id);
    //     this.cxc = data;
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   await this.toggleLoading();
    //   this.toPrint();

    //   if (this.$store.state.ids.length == 0) {
    //     this.$router.push(`/cxcs/${this.cxc._id}`);
    //   } else {
    //     this.$router.push(`/cxcslot/new`);
    //   }

    //   // this.$router.push(`/cxcs/${this.cxc._id}`);
    // },

    // async handleUpdate() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       await updateCxc(this.$route.params.id, this.cxc);
    //       this.$router.push("/cxcs");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },

    // async handleDelete() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       deleteCxc(this.$route.params.id);
    //       this.$router.push("/cxcs");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },

  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadCxc(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
