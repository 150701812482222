
const filters = ["Todos", "Empresa", "Sucursal", "Grupo", "Departamento", "Función"];

export default {
  props: ["filterAreas", "search", "filteredAreas"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },
};
