import { Llamada } from "@/interfaces/Llamada";
import { AxiosResponse } from "axios";
import axios from "./axios";

//Básicos----------------------------------------------------------------------------------------------------
//Cargar Registros
export const createLlamadaa = async (
  llamada: Llamada
): Promise<AxiosResponse> => await axios.post("/llamadas/Savea", llamada);

// Crear un Solo Registro
export const createLlamada = async (
  llamada: Llamada
): Promise<AxiosResponse> => await axios.post("/llamadas/Save", llamada);

// Leer Todos los registros
export const getLlamadas = async (criterio: any): Promise<AxiosResponse<Llamada[]>> =>
  await axios.post("/llamadas/get", criterio);

// Leer Todos los registros de un Especialista
export const getServsByEsp = async (criterio: any): Promise<AxiosResponse<Llamada[]>> =>
  await axios.post("/llamadas/getServsByEsp", criterio);

//Leer un solo Registro por ID
export const getLlamada = async (
  id: string
): Promise<AxiosResponse<Llamada>> =>
  await axios.get(`/llamadas/GetById/${id}`);

//Leer el último Registro
export const getOneLlamada = async (): Promise<AxiosResponse<Llamada>> =>
  await axios.get("/llamadas/GetOne");

//Actualizar un Registro
export const updateLlamada = async (
  id: string,
  newLlamada: Llamada
): Promise<AxiosResponse<Llamada>> =>
  await axios.put(`/llamadas/Update/${id}`, newLlamada);

//Actualizar todos los Registros

//Eliminar un Registro
export const deleteLlamada = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/llamadas/Delete/${id}`);

//Eliminar todos los Registros
export const eliminateLlamadas = async (
  llamada: Llamada
): Promise<AxiosResponse> => await axios.post("/llamadas/delete", llamada);

//Reportes----------------------------------------------------------------------------------------------------
// Get Llamadas Agrupados por Día
export const getproddayli = async (): Promise<AxiosResponse<Llamada[]>> =>
  await axios.get("/llamadas/getproddayli");

// Get Llamadas Agrupadas por Estatus
export const getllamadasCant = async (): Promise<AxiosResponse<Llamada[]>> =>
  await axios.get("/llamadas/getGpedByStat");

// Get Llamadas Agrupadas por Cliente
export const getfactsGpedByCli = async (): Promise<AxiosResponse<Llamada[]>> =>
  await axios.get("/llamadas/getfactsGpedByCli");

//Funciones----------------------------------------------------------------------------------------------------
//Actualizar Un Registro
export const updateOne = async (llamada: Llamada): Promise<AxiosResponse> =>
  await axios.post("/llamadas/updateOne", llamada);

// Leer todas las llamadas por Aseguradora
export const GetByArs = async (
  aseguradoras: Llamada
): Promise<AxiosResponse> =>
  await axios.post("/llamadas/GetByArs", aseguradoras);
