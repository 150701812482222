
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nav-bar",

  data() {
    return {
      desplegar: "fas fa-caret-down",
      showMenu: false,
      showName: false,

      showFactura: false,
      navMenuClas: ["menu-navegacion"],
    };
  },

  methods: {
    hamburger(texto: string) {
      switch (texto) {
        case "IngresosPediatricos":
          this.$router.push("/ingresos");
          break;
        case "Medicos":
          this.$router.push("/medicos");
          break;
        case "Consultorios":
          this.$router.push("/consultorios");
          break;
        case "Cxcs":
          this.$router.push("/cxcs");
          break;
        case "Facturas":
          this.$router.push("/facturas");
          break;
        case "Facturaas":
          this.$router.push("/facturaas");
          break;
        case "Empleados":
          this.$router.push("/empleados");
          break;
        case "Reclamos":
          this.$router.push("/reclamos");
          break;
        case "Especialistas":
          this.$router.push("/especialistas");
          break;
        case "Llamadas":
          this.$router.push("/llamadas");
          break;
        case "Productos":
          this.$router.push("/productos");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
        this.desplegar = "fas fa-caret-up";
      } else {
        this.navMenuClas.pop();
        this.desplegar = "fas fa-caret-down";
      }
    },

    tglMenu() {
      this.showMenu = !this.showMenu;
    },

    tshowFactura() {
      (this.showName = false), (this.showFactura = !this.showFactura);
    },

    tglName() {
      this.showName = !this.showName;
      this.showFactura = false;
    },

    salir() {
      this.$router.push("/");
      this.$store.dispatch("logoutAction");
    },
  },
});
