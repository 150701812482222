
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Cxc } from "@/interfaces/Cxc";
// import { Funcion } from "@/interfaces/Funcion";
// import { Vitola } from "@/interfaces/Vitola";
// import { Cxc } from "@/interfaces/Cxc";
// import {
//   getPeg,
//   getMez,
//   getEmp,
// } from "@/services/datasamicj/FacturaService";
// import { getLigas } from "@/services/cruzjiminiancj/Liga";
import {
getcxcs
} from "@/services/datasamicj/FacturaService";
// import { getVitolas } from "@/services/cruzjiminiancj/Vitola";
// import { getFuncions } from "@/services/cruzjiminiancj/Funcion";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "cxcs-form",
  components: {
    Navbar,
  },
  data() {
    return {
      cxcs: [] as Cxc[],
      subs: [],
      preSubs: [],
      almacenes: [
        {
          desc: "Almacén No. 01 (General)",
          subs: ["Donaciones", "Venta / Servicios", "Muestras Médicas"],
        },
        {
          desc: "Almacén No. 02 (Suministro de Oficina y Limpieza)",
          subs: [
            "Sub-01 (Materiales de Oficina)",
            "Sub-02 (Materiales de Limpieza)",
          ],
        },
        {
          desc: "Almacén No. 03 (Abastecimiento)",
          subs: [
            "Sub-01 (Carro de Paro UCI)",
            "Sub-02 (Carro de Paro 2do Piso)",
            "Sub-03 (Carro de Paro Perinato)",
            "Sub-04 (Carro de Paro Quirófano)",
            "Sub-05 (Carro de Paro 3er Piso)",
          ],
        },
        {
          desc: "Almacén No. 04 (Emergencia, Quirófano y Ambulatorio)",
          subs: [
            "Sub-01 (Emergencia)",
            "Sub-02 (Depósito Quirófano)",
            "Sub-03 (Imágenes)",
            "Sub-04 (Laboratorio Clínico)",
            "Sub-05 (Laboratorio Biomolecular)",
            "Sub-06 (Farmacia 3er Piso)",
            "Sub-07 (Emergencia Pediatrica)",
          ],
        },
        {
          desc: "Almacén No. 70 (Cocina)",
          subs: [
            "Sub-01 (Alimentos)",
            "Sub-02 (Bebidas y Líquidos)",
            "Sub-03 (Suministros)",
            "Sub-04 (Utencilios)",
          ],
        },
        {
          desc: "Almacén No. 98 (Vencidos)",
          subs: [],
        },
        {
          desc: "Almacén No. 99 (Averiado)",
          subs: [],
        },
      ],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // cxcs: [] as Cxc[],
      // cxcsEmp: [] as Cxc[],
      // cxcsPeg: [] as Cxc[],
      // cxcsMez: [] as Cxc[],
      campoFocus: "fecha",
      mensageError: "Error",
      mensageExito: "Cxc Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Cxc?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      cxc: {} as Cxc,
      oneCxc: {} as Cxc,
      one: {},
      nextNo: Number,
      cxcSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    this.fechaActual = new Date();
    // this.loadCxcs();
    // this.seguros = [
    //   "Victor Osiris Alcántara Castro",
    //   "Abel David Ventura Rivas",
    // ];
    if (this.$route.fullPath == "/cxcs/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Cxc";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Cxc";
    }

    if (this.modoForm == "add") {
      this.cxc.no = 1;
      this.cxc.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadCxc(this.$route.params.id);
        // this.defSubs();

        // this.cxc.finVac = new Date();
        // this.cxc.fechaNac = new Date();
        // this.cxc.fechaCont = new Date();
      }
      this.fixTime();
    }

    this.focus();
    // this.loadCxcs();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterCxcs();
  },

  methods: {
    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/cxcs/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Cxc";
      this.showDeleteMethod();
      await this.loadCxc(id);
      this.defSubs();

      // this.cxc.finVac = new Date();
      // this.cxc.fechaNac = new Date();
      // this.cxc.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();
      try {
        const res = await getcxcs(this.criterio);
        this.cxcs = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (this.cxc.barcode !== "Todos") {
        this.cxcs = this.cxcs.filter((cxc: Cxc) => {
          return cxc.nombre
            .toLowerCase()
            .includes(this.cxc.barcode.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("division").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.cxc.mesVenc = moment(date).format("MM");
      this.cxc.anoVenc = moment(date).format("YYYY");
    },

    // defSubs() {
    //   this.preSubs = this.almacenes.filter((almacen: Cxc) => {
    //     return almacen.desc
    //       .toLowerCase()
    //       .includes(this.cxc.almacn.toLowerCase());
    //   });
    //   this.subs = this.preSubs[0].subs;
    // },

    setSeguro(seguro: string, seguro2: string) {
      this.cxc.supervisor = seguro + " " + seguro2;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("cod").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("cod").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.cxc.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.cxc.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.cxc.edad = years;
    },

    // async filterCxcs() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.cxcsPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.cxcsEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.cxcsMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    async loadCxcs() {
      this.toggleLoading();
      try {
        const res = await getcxcs(this.criterio);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    fixTime() {
      if (this.cxc.fecha) {
        this.cxc.fecha = this.formatDateToFix(this.cxc.fecha, false);
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(4, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    // async loadCxc(id: string) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await getCxc(id);
    //     this.cxc = data;
    //     // this.fixTime();
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    // async handleUpdate() {
    //   this.toggleLoading();
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       this.cxc.userMod = this.$store.state.user.usuario;
    //       await updateCxc(this.$route.params.id, this.cxc);
    //       this.addMessage();
    //       this.$router.push("/cxcs");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    //   // this.toggleAlert();
    // },

    // async handleDelete() {
    //   if (confirm(this.mensageConfirm)) {
    //     try {
    //       if (typeof this.$route.params.id === "string") {
    //         await deleteCxc(this.$route.params.id);
    //         this.addMessage();
    //         this.$router.push("/cxcs");
    //       }
    //     } catch (error) {
    //       //console.error(error);
    //     }
    //   }
    // },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcCxc() {
      (this.cxc.retension = this.cxc.bruto * 0.1),
        (this.cxc.neto = this.cxc.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("MM");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.cxc.fecha = new Date();
      // this.cxc.fechaNac = new Date();
      // this.cxc.fechaCont = new Date();
    },

    // async loadOneCxc() {
    //   try {
    //     const res = await getOneCxc();
    //     this.oneCxc = res.data;
    //     this.one = this.oneCxc[0];
    //     if (typeof this.one.no === "number") {
    //       this.nextNo = this.one.no + 1;
    //     } else {
    //       this.one.no = 0;
    //       this.nextNo = this.one.no + 1;
    //     }
    //     if (this.nextNo == null) {
    //       this.nextNo = 0;
    //     }
    //     this.cxc.no = this.nextNo;
    //     this.cxc.principal = this.nextNo;
    //     this.cxc.principal = this.nextNo;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    // async saveCxca() {
    //   await this.loadOneCxc();
    //   try {
    //     const res = await createCxca(this.cxc);
    //     // // console.log(res);
    //   } catch (error) {
    //     // // console.error(error);
    //   }
    // },

    // async saveCxc() {
    //   this.toggleLoading();
    //   try {
    //     try {
    //       const res = await getOneCxc();
    //       this.oneCxc = res.data;
    //       this.one = this.oneCxc[0];
    //       this.nextNo = this.one.no + 1;
    //       this.cxc.no = this.nextNo;
    //     } catch (error) {
    //       // // console.error(error);
    //     }
    //     this.cxc.userReg = this.$store.state.user.usuario;
    //     const res = await createCxc(this.cxc).then(
    //       (res) => {
    //         this.error = this.respuesta = res.data.title;
    //         // this.$router.push("/");
    //         this.res = res;
    //         this.respuesta = res.data;
    //         this.addMessage();
    //       },
    //       (err) => {
    //         // console.log(err.response);
    //         this.error = err.response.data.error;
    //       }
    //     );
    //     // this.$router.push("/cxcs/");
    //   } catch (error) {
    //     // // console.error(error);
    //   }
    //   await this.toggleLoading();
    //   if (this.error !== this.mensageError) {
    //     await this.cleanFields();
    //   }
    //   await this.fillFields();
    //   document.getElementById(this.campoFocus).focus();
    //   this.toggleAlert();
    // },

    // async deleteAllCxcs() {
    //   try {
    //     const res = await eliminateCxcs(this.cxc);
    //     // // console.log(res);
    //   } catch (error) {
    //     // // console.error(error);
    //   }
    // },

    cleanFields() {
      this.cxc.fecha = "";
      this.cxc.especialidad = "";
      this.cxc.nombre = "";
      this.cxc.telefonos = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
