
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Facturaa } from "@/interfaces/Facturaa";
import {
  createFacturaa,
  eliminateFacturaas,
  createFacturaaa,
  getOneFacturaa,
} from "@/services/cruzjiminiancj/FacturaaService";

// import {
//   APIgetFacturaa,
//   getFacturaas,
// } from "@/services/datasamicj/FacturaaService";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "facturaas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      facturaa: {} as Facturaa,
      oneFacturaa: {} as Facturaa,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.facturaa.no = 1;
    // this.defFields();
    this.focus();
  },

  methods: {
    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    fixTime() {
      this.facturaa.fecha = this.formatDateToFix(
        this.facturaa.fecha,
        true
      );
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    // async getFacturaa() {
    //   this.toggleLoading();
    //   this.documento.idfact = this.facturaa.idfact;
    //   if (this.documento) {
    //     try {
    //       const res = await APIgetFacturaa(this.documento);
    //       // const res = await getFacturaas();
    //       // this.facturaa = res.data;
    //       // Asignar Campos Seleccionandolos
    //       this.facturaa.idfact = res.data.idfact;
    //       this.facturaa.id_ars = res.data.id_ars;
    //       this.facturaa.nom = res.data.nom;
    //       this.facturaa.nro_autorizacion_salida =
    //         res.data.nro_autorizacion_salida;
    //       this.facturaa.fecha = res.data.fecha;
    //       this.facturaa.numero_afiliado = res.data.numero_afiliado;
    //       this.facturaa.rnc = res.data.rnc;
    //       this.facturaa.tipo_facturaa = res.data.tipo_facturaa;
    //       this.facturaa.cobertura = res.data.cobertura;
    //       this.facturaa.total_servicio = res.data.total_servicio;

    //       this.facturaa.status = this.$store.state.user.defaultStatus;
    //       this.facturaa.actividad = [];
    //       this.facturaa.actividad.push({
    //         description: this.$store.state.user.defaultStatus,
    //         date: new Date(),
    //         user: this.$store.state.user.usuario,
    //         detalles: "",
    //       });

    //       this.fixTime();
    //       this.error = "";
    //     } catch (error) {
    //       // console.error(error);
    //     }
    //   }
    //   await this.toggleLoading();
    //   if (this.facturaa.rnc == "") {
    //     this.facturaa.rnc = "-";
    //   }
    //   this.focus();
    // },

    isError(message: string) {
      if (message == "Facturaa Registrada Exitosamente") {
        return "success";
      } else if (message == "Ya Existe una Facturaa Registrada con este Id") {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcFacturaa() {
      (this.facturaa.retension = this.facturaa.bruto * 0.1),
        (this.facturaa.neto = this.facturaa.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    // defFields() {
    // this.facturaa.status = this.$store.state.user.defaultStatus;
    // this.actividad = "4 - Recibido por Reclamaciones Médicas";
    // this.facturaa.actividad.push(this.actividad);
    // },

    async loadOneFacturaa() {
      try {
        const res = await getOneFacturaa();
        this.oneFacturaa = res.data;
        this.one = this.oneFacturaa[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.facturaa.no = this.nextNo;
        this.facturaa.principal = this.nextNo;
        this.facturaa.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveFacturaaa() {
      await this.loadOneFacturaa();
      try {
        const res = await createFacturaaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFacturaa() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneFacturaa();
          this.oneFacturaa = res.data;
          this.one = this.oneFacturaa[0];
          this.nextNo = this.one.no + 1;
          this.facturaa.no = this.nextNo;
          this.facturaa.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.facturaa.status = this.$store.state.user.defaultStatus;
        this.facturaa.userReg = this.$store.state.user.usuario;
        const res = await createFacturaa(this.facturaa).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/facturaas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      // await this.defFields();
      document.getElementById("idfact").focus();
      this.toggleAlert();
    },

    async deleteAllFacturaas() {
      try {
        const res = await eliminateFacturaas(this.facturaa);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      // this.facturaa.no = "";
      this.facturaa.idfact = "";
      this.facturaa.id_ars = "";
      this.facturaa.nom = "";
      this.facturaa.nro_autorizacion_salida = "";
      this.facturaa.fecha = "";
      this.facturaa.numero_afiliado = "";
      this.facturaa.rnc = "";
      this.facturaa.tipo_facturaa = "";
      this.facturaa.cobertura = "";
      this.facturaa.total_servicio = "";
      // this.facturaa.status = "";
      // this.facturaa.actividad = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("idfact").focus();
    },
  },
});
