import { Especialista } from "@/interfaces/Especialista";
import { AxiosResponse } from "axios";
import axios from "./axios";

//Básicos----------------------------------------------------------------------------------------------------
//Cargar Registros
export const createEspecialistaa = async (
  especialista: Especialista
): Promise<AxiosResponse> => await axios.post("/especialistas/Savea", especialista);

// Crear un Solo Registro
export const createEspecialista = async (
  especialista: Especialista
): Promise<AxiosResponse> => await axios.post("/especialistas/Save", especialista);

// Leer Todos los registros
export const getEspecialistas = async (): Promise<AxiosResponse<Especialista[]>> =>
  await axios.get("/especialistas/get");

//Leer un solo Registro por ID
export const getEspecialista = async (
  id: string
): Promise<AxiosResponse<Especialista>> =>
  await axios.get(`/especialistas/GetById/${id}`);

//Leer el último Registro
export const getOneEspecialista = async (): Promise<AxiosResponse<Especialista>> =>
  await axios.get("/especialistas/GetOne");

//Actualizar un Registro
export const updateEspecialista = async (
  id: string,
  newEspecialista: Especialista
): Promise<AxiosResponse<Especialista>> =>
  await axios.put(`/especialistas/Update/${id}`, newEspecialista);

//Actualizar todos los Registros

//Eliminar un Registro
export const deleteEspecialista = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/especialistas/Delete/${id}`);

//Eliminar todos los Registros
export const eliminateEspecialistas = async (
  especialista: Especialista
): Promise<AxiosResponse> => await axios.post("/especialistas/delete", especialista);

//Reportes----------------------------------------------------------------------------------------------------
// Get Especialistas Agrupados por Día
export const getproddayli = async (): Promise<AxiosResponse<Especialista[]>> =>
  await axios.get("/especialistas/getproddayli");

// Get Especialistas Agrupadas por Estatus
export const getespecialistasCant = async (): Promise<AxiosResponse<Especialista[]>> =>
  await axios.get("/especialistas/getGpedByStat");

// Get Especialistas Agrupadas por Cliente
export const getfactsGpedByCli = async (): Promise<AxiosResponse<Especialista[]>> =>
  await axios.get("/especialistas/getfactsGpedByCli");

//Funciones----------------------------------------------------------------------------------------------------
//Actualizar Un Registro
export const updateOne = async (especialista: Especialista): Promise<AxiosResponse> =>
  await axios.post("/especialistas/updateOne", especialista);

// Leer todas las especialistas por Aseguradora
export const GetByArs = async (
  aseguradoras: Especialista
): Promise<AxiosResponse> =>
  await axios.post("/especialistas/GetByArs", aseguradoras);
