
import { defineComponent } from "vue";
import { Empleado } from "@/interfaces/Empleado";
import {
  deleteEmpleado,
  getEmpleado,
  updateEmpleado,
} from "@/services/empleadoscj/Empleado";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "empleado-list",
  data() {
    return {
      cargando: false,
      empleado: {} as Empleado,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadEmpleado(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getEmpleado(id);
        this.empleado = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/empleados/${this.empleado._id}`);
      } else {
        this.$router.push(`/empleadoslot/new`);
      }

      // this.$router.push(`/empleados/${this.empleado._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateEmpleado(this.$route.params.id, this.empleado);
          this.$router.push("/empleados");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteEmpleado(this.$route.params.id);
          this.$router.push("/empleados");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadEmpleado(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
