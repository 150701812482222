
import Filters from "@/components/Reclamo/FiltersComponent.vue";
import ListadoComponent from "@/components/Reclamo/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { Reclamo } from "@/interfaces/Reclamo";
import { Especialista } from "@/interfaces/Especialista";
import { getReclamos, getServsByEsp } from "@/services/cruzjiminiancj/Reclamo";
import { getEspecialistas } from "@/services/cruzjiminiancj/Especialista";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      criterio: {} as any,
      navMenuClas2: ["menu-navegacion2"],
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      reclamos: [] as Reclamo[],
      reclamosFiltradas: [] as Reclamo[],
      especialistas: [] as Especialista[],
      str: "",
      type: "",
    };
  },

  methods: {
    async loadEspecialistas() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadServsByEsp() {
      this.toggleLoading();
      try {
        const res = await getServsByEsp(this.criterio);
        this.reclamos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    filtrarEsp() {
      this.reclamos = this.reclamosFiltradas.filter((reclamo: Reclamo) => {
        return reclamo.nombPac
          .toLowerCase()
          .includes(this.criterio.paciente.toLowerCase());
      });
    },

    fixTime() {
      if (this.criterio.desde) {
        this.criterio.desde = this.formatDateToFix(this.criterio.desde, false);
      }
    },

    formatDateToFix(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("yyyy-MM-DD");
    },

    hamburger(texto: string) {
      switch (texto) {
        case "Aseguradoras":
          this.$router.push("/aseguradoras");
          break;
        case "Especialistas":
          this.$router.push("/especialistas");
          break;
        case "Reclamos":
          this.$router.push("/reclamos");
          break;
        case "Productos":
          this.$router.push("/productos");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
        this.desplegar = "fas fa-caret-up";
      } else {
        this.navMenuClas.pop();
        this.desplegar = "fas fa-caret-down";
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadReclamos2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterReclamos(catName: string) {
      try {
        const res = await getReclamos(this.criterio);
        this.reclamos = res.data;
        this.reclamosFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.reclamos = this.reclamos.filter((reclamo: Reclamo) => {
          return reclamo.aflNSS === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getReclamos(this.criterio);
        this.reclamos = res.data;
        this.reclamosFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.reclamos = this.reclamos.filter((reclamo: Reclamo) => {
          return reclamo.aflNSS.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadReclamos() {
      this.toggleLoading();
      this.criterio.hasta = this.criterio.desde;
      try {
        const res = await getReclamos(this.criterio);
        this.reclamos = res.data;
        this.reclamosFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadReclamos2() {
      this.toggleLoading();
      try {
        const res = await getReclamos(this.criterio);
        this.reclamos = res.data;
        this.reclamosFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.criterio.desde = new Date();
    this.fixTime();
    this.loadReclamos();
    this.loadEspecialistas();
    // this.pusherSubscribe();
  },
};
