
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Usuario } from "@/interfaces/Usuario";
import { getUsuarios } from "@/services/cruzjiminiancj/UsuarioService";
import {
  deleteUsuario,
  getUsuario,
  updateUsuario,
} from "@/services/cruzjiminiancj/UsuarioService";
import numeral from "numeral";

export default defineComponent({
  name: "usuario-list",
  components: {
    Navbar,
  },
  data() {
    return {
      usuarios: [] as Usuario[],
      currentUsuario: {} as Usuario,
      estado: {} as object,
      cargando: false,
    };
  },
  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    async loadUsuario(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getUsuario(id);
        this.currentUsuario = data;
        this.estado = this.currentUsuario.estado;
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          await updateUsuario(this.$route.params.id, this.currentUsuario);
          this.$router.push("/usuarios");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleDelete() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          await deleteUsuario(this.$route.params.id);
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      this.$router.push("/usuarios");
    },

    async loadUsuarios() {
      this.toggleLoading();
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadUsuario(this.$route.params.id);
    }
    this.loadUsuarios();
  },
});
