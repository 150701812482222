import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3a1e266"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gridlistareportes" }
const _hoisted_2 = { class: "card-header d-flex w-100 justify-content-between" }
const _hoisted_3 = { class: "mb-1 text-white" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "fas fa-spinner",
  _mstvisible: "2"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.reportes, (reporte, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([$options.toColor(reporte.type), "card text-white bg-primary mb-3"]),
        style: {"max-width":"20rem"}
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(reporte.solicitante), 1),
          _createElementVNode("small", null, [
            _createElementVNode("span", {
              class: _normalizeClass($options.toColor2(reporte.status))
            }, _toDisplayString(reporte.status), 3),
            _withDirectives(_createElementVNode("i", {
              onClick: ($event: any) => (
              $options.priorizar(this.reportes, index, index - 1, reporte.description)
            ),
              style: {"cursor":"pointer"},
              class: "fas fa-chevron-circle-up"
            }, null, 8, _hoisted_4), [
              [_vShow, !$data.cargando]
            ]),
            _withDirectives(_createElementVNode("i", _hoisted_5, null, 512), [
              [_vShow, $data.cargando]
            ])
          ])
        ]),
        _createElementVNode("div", {
          style: {"cursor":"pointer"},
          onClick: ($event: any) => (this.$router.push(`/reporte2s/${reporte._id}`)),
          class: "card-body"
        }, [
          _createElementVNode("h4", _hoisted_7, _toDisplayString(reporte.description), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(reporte.detalles), 1),
          _createElementVNode("small", null, [
            _createElementVNode("span", null, _toDisplayString($options.newFormatDate(reporte.createdAt)) + " (" + _toDisplayString($options.newFormatDate2(reporte.createdAt)) + ")", 1)
          ])
        ], 8, _hoisted_6)
      ], 2))
    }), 128))
  ]))
}