
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Ingreso } from "@/interfaces/Ingreso";
import {
  createIngreso,
  eliminateIngresos,
  createIngresoa,
  getOneIngreso,
} from "@/services/cruzjiminiancj/IngresoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "ingresos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      ingreso: {} as Ingreso,
      oneIngreso: {} as Ingreso,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.defFields();
    this.focus();
  },

  methods: {
    // fixTime() {
    //   this.ingreso.fecha = this.formatDate(this.ingreso.fecha);
    // },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcIngreso() {
      (this.ingreso.retension = this.ingreso.bruto * 0.1),
        (this.ingreso.neto = this.ingreso.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    async defFields() {
      this.ingreso.medico = this.$store.state.user.usuario;
      this.ingreso.fecha = new Date();
      // await this.fixTime();

      var currentTime = new Date();
      var year = currentTime.getFullYear();
      console.log(year);

      this.ingreso.ano = year;

      var month = currentTime.getMonth();
      console.log(month);

      this.ingreso.mes = month + 1;
    },

    async loadOneIngreso() {
      try {
        const res = await getOneIngreso();
        this.oneIngreso = res.data;
        this.one = this.oneIngreso[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.ingreso.no = this.nextNo;
        this.ingreso.principal = this.nextNo;
        this.ingreso.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveIngresoa() {
      await this.loadOneIngreso();
      try {
        const res = await createIngresoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveIngreso() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneIngreso();
          this.oneIngreso = res.data;
          this.one = this.oneIngreso[0];
          this.nextNo = this.one.no + 1;
          this.ingreso.no = this.nextNo;
          this.ingreso.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.ingreso.userReg = this.$store.state.user.usuario;
        const res = await createIngreso(this.ingreso);
        this.$router.push("/ingresos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      await this.defFields();
      document.getElementById("expedienteNo").focus();
      this.toggleAlert();
    },

    async deleteAllIngresos() {
      try {
        const res = await eliminateIngresos(this.ingreso);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.ingreso.fecha = "";
      this.ingreso.ano = "";
      this.ingreso.mes = "";
      this.ingreso.medico = "";
      this.ingreso.expedienteNo = "";
      this.defFields();
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("expedienteNo").focus();
    },
  },
});
