
import Filters from "@/components/Usuario/FiltersComponent.vue";
import UsuariosComponent from "@/components/Usuario/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Usuario } from "@/interfaces/Usuario";
import { getUsuarios } from "@/services/cruzjiminiancj/UsuarioService";

export default {
  name: "App",

  components: {
    Filters,
    UsuariosComponent,
    Navbar,
  },

  data() {
    return {
      cargando: false,
      usuarios: [] as Usuario[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterUsuarios(catName: string) {
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.usuarios = this.usuarios.filter((usuario: Usuario) => {
          return usuario.role === catName;
        });
      }
    },

    async search(term: string) {
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.usuarios = this.usuarios.filter((usuario: Usuario) => {
        return usuario.nombre.toLowerCase().includes(term.toLowerCase());
      });
    },

    async loadUsuarios() {
      this.toggleLoading();
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadUsuarios();
  },
};
