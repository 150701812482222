
import Navbar from "@/components/Navbar.vue";
import { getMejPend } from "@/services/cruzjiminiancj/MejoraService";
import { getUsuariosCant } from "@/services/cruzjiminiancj/UsuarioService";
import { getUrl } from "@/services/isys/QuicksightService";
import { getproddayli } from "@/services/cruzjiminiancj/Producto";
import {
  getfacturasCant,
  getfactsGpedByCli,
} from "@/services/cruzjiminiancj/FacturaService";
import {
  getfundbyper,
  getsubsibyper,
} from "@/services/datasamicj/FacturaService";
import numeral from "numeral";
import Pusher from "pusher-js";
import {
  embedDashboard,
  embedVisual,
  embedSession,
  embedQSearchBar,
} from "amazon-quicksight-embedding-sdk";

export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      dias: [] as any,
      presupuesto: 400000,
      presupuesto2: 2335000,
      usuariosCant: [] as any,
      facturasCant: [] as any,
      factsGpedByCli: [] as any,
      mejorasPend: [] as any,
      totales: {} as any,
      cargando: false,
      capurl:""

    };
  },

  methods: {
    async defineurl(){
      try {
        const res = await getUrl();
        this.capurl= res.data;
        this.myEmbedVisual();
      } catch (error) {
        console.log(error)
      }
    },
    myEmbedVisual() {
      const containerDiv = document.getElementById("embeddingContainer");
      const options = {
        url: this.capurl,
        container: containerDiv,
        height: "600px"
      };

      const embeddedDashboardExperience = embedDashboard(options);
      // let embeddedVisualExperience;
      // embeddedVisualExperience = QuickSightEmbedding.embedDashboard(options);

      // embeddedVisualExperience.on("error", onerror);
      embeddedDashboardExperience.on("load", embedVisual);
    },

    formatTable(dif: number) {
      if (dif < 0) {
        return "der red";
      } else {
        return "der green";
      }
    },

    getMonth(month: number) {
      switch (month) {
        case 1:
          return "Enero";
        case 2:
          return "Febrero";
        case 3:
          return "Marzo";
        case 4:
          return "Abril";
        case 5:
          return "Mayo";
        case 6:
          return "Junio";
        case 7:
          return "Julio";
        case 8:
          return "Agosto";
        case 9:
          return "Septiembre";
        case 10:
          return "Octubre";
        case 11:
          return "Noviembre";
        case 12:
          return "Diciembre";

        default:
          return "Error";
      }
    },
    getARS(id_ars: string) {
      switch (id_ars) {
        case "5":
          return "ARS CMD";
        case "14":
          return "MAPFRE SALUD ARS, S.A.";
        case "8":
          return "FUTURO";
        case "12":
          return "META SALUD";
        case "23":
          return "YUNEN";
        case "17":
          return "RESERVAS";
        case "13":
          return "MONUMENTAL";
        case "4":
          return "ASEMAP";
        case "1":
          return "ARS APS S A";
        case "21":
          return "SIMAG";
        case "16":
          return "RENACER";
        case "59":
          return "GRUPO MEDICO ASOCIADO";
        case "9":
          return "GRUPO MEDICO ASOCIADO";
        case "24":
          return "PRIMERA  ARS DE HUMANO";
        case "22":
          return "UNIVERSAL";
        case "29":
          return "ALBA GAS S.R.L.";
        case "10":
          return "HUMANO SEGUROS";
        case "20":
          return "SENASA CONTRIBUTIVO";
        case "61":
          return "SENASA SUBSIDIADO";
        case "18":
          return "SEMMA";
        case "65":
          return "IDOPPRIL";
        default:
          return "ARS Descripcion";
      }
    },

    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },

    valorTotal() {
      this.totales.dias = this.dias.length;
      this.totales.cantProductos = this.dias.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );

      // this.totales.count = this.periodos.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.cobertura = this.periodos.reduce(
      //   (accum: any, item: any) => accum + item.cobertura,
      //   0
      // );
      // this.totales.facturas = this.facturasCant.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.facturas2 = this.factsGpedByCli.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.cobertura2 = this.factsGpedByCli.reduce(
      //   (accum: any, item: any) => accum + item.cobertura,
      //   0
      // );
      // this.totales.cantClientes = this.factsGpedByCli.length;
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("f2c972e55a9c9eac5f9a", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadfacturasCant2();
        this.loadfactsGpedByCli2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    formatNumber(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0");
      }
    },

    formatNumberPcto(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0.00");
      }
    },

    async loadMejorasPendientes() {
      this.toggleLoading();
      try {
        const res = await getMejPend();
        this.mejorasPend = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadUsuariosCant() {
      this.toggleLoading();
      try {
        const res = await getUsuariosCant();
        this.usuariosCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadfacturasCant() {
      this.toggleLoading();
      try {
        const res = await getfacturasCant();
        this.facturasCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadfundbyper() {
      this.toggleLoading();
      try {
        const res = await getfundbyper();
        this.periodos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadsubsibyper() {
      this.toggleLoading();
      try {
        const res = await getsubsibyper();
        this.periodos2 = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadproddayli() {
      this.toggleLoading();
      try {
        const res = await getproddayli();
        this.dias = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadfactsGpedByCli() {
      this.toggleLoading();
      try {
        const res = await getfactsGpedByCli();
        this.factsGpedByCli = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadfactsGpedByCli2() {
      try {
        const res = await getfactsGpedByCli();
        this.factsGpedByCli = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadfacturasCant2() {
      // this.toggleLoading();
      try {
        const res = await getfacturasCant();
        this.facturasCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },
  },

  mounted() {
    this.defineurl();

    this.loadMejorasPendientes();
    this.loadUsuariosCant();
    this.loadfundbyper();
    this.loadsubsibyper();
    this.loadproddayli();
    // this.loadfacturasCant();
    // this.loadfactsGpedByCli();
    // this.pusherSubscribe();
  },

  updated() {
    this.valorTotal();
  },
};
