
import Filters from "@/components/Facturaa/FiltersComponent.vue";
import ListadoComponent from "@/components/Facturaa/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Facturaa } from "@/interfaces/Facturaa";
import {
  getFacturaas,
  GetByArs,
} from "@/services/cruzjiminiancj/FacturaaService";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      numProcess: "",
      descProcess: "",
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      facturaas: [] as Facturaa[],
      str: "",
      type: "",
    };
  },

  methods: {
    valorTotal() {
      // alert("Calculanding");
      this.$store.state.user.totalesFacturaas = this.$store.state.user.facturaas.length;
      this.$store.state.user.totalesCobertura = this.$store.state.user.facturaas.reduce(
        (accum: any, item: any) => accum + item.cobertura,
        0
      );
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("ec64cab5b5fa0b45d374", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadFacturaas2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterFacturaas(catName: string) {
      // alert("Filtering");
      this.toggleLoading();
      this.$store.state.user.status = catName;
      this.$store.state.user.numProcess = this.$store.state.user.status.substr(
        0,
        1
      );
      this.$store.state.user.descProcess = this.$store.state.user.status.substr(
        4,
        70
      );
      try {
        // const res = await getFacturaas();
        const res = await GetByArs(this.$store.state.user);
        // this.facturaas = res.data;
        this.$store.state.user.facturaas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.$store.state.user.facturaas = this.$store.state.user.facturaas.filter(
          (facturaa: Facturaa) => {
            return facturaa.status === catName;
          }
        );
      }
      this.valorTotal();
      this.toggleLoading();
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        // const res = await getFacturaas();
        const res = await GetByArs(this.$store.state.user);
        this.facturaas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.facturaas = this.facturaas.filter((facturaa: Facturaa) => {
          return facturaa.idfact.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadFacturaas() {
      // alert("Loading");
      this.toggleLoading();
      try {
        // const res = await getFacturaas();
        const res = await GetByArs(this.$store.state.user);
        this.$store.state.user.facturaas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.valorTotal();
      this.toggleLoading();
    },

    async loadFacturaas2() {
      this.toggleLoading();
      try {
        // const res = await getFacturaas();
        const res = await GetByArs(this.$store.state.user);
        this.facturaas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadFacturaas();
    // this.pusherSubscribe();
  },
};
