
import Filters from "@/components/Ingreso/FiltersComponent.vue";
import ListadoAllComponent from "@/components/Ingreso/ListAllComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Ingreso } from "@/interfaces/Ingreso";
import { getIngresos } from "@/services/cruzjiminiancj/IngresoService";

export default {
  name: "App",

  components: {
    Filters,
    ListadoAllComponent,
    Navbar,
  },

  data() {
    return {
      cargando: false,
      ingresos: [] as Ingreso[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },

    // async filterIngresos(catName: string) {
    //   try {
    //     const res = await getIngresos();
    //     this.ingresos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   if (catName !== "Todos") {
    //     this.ingresos = this.ingresos.filter((ingreso: Ingreso) => {
    //       return ingreso.type === catName;
    //     });
    //   }
    // },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getIngresos();
        this.ingresos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.ingresos = this.ingresos.filter((ingreso: Ingreso) => {
          return ingreso.expedienteNo
            .toLowerCase()
            .includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadIngresos() {
      this.toggleLoading();
      try {
        const res = await getIngresos();
        this.ingresos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadIngresos();
  },
};
