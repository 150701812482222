
const filters = ["Todos", "Error", "Mejora", "Función"];

export default {
  props: ["filterMejoras", "search", "filteredMejoras"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },
};
