
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Ingreso } from "@/interfaces/Ingreso";
import {
  deleteIngreso,
  getIngreso,
  updateIngreso,
} from "@/services/cruzjiminiancj/IngresoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "ingreso-list",
  components: {
    Navbar,
  },
  data() {
    return {
      showAlert: false,
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      ingreso: {} as Ingreso,
      estado: {} as object,
    };
  },
  methods: {
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async facturar() {
      this.ingreso.cantPrefactura = 0;
      this.ingreso.cantFactura = 1;
      this.ingreso.credito = 0;
      this.ingreso.debito = 0;
      this.ingreso.subTipo = "Factura";
      this.ingreso.factura = this.ingreso.prefactura;
      this.ingreso.prefactura = 0;
      await this.selectCliente(this.ingreso.descCliente);
      this.ingreso.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );
      await this.selectMedico(this.ingreso.medico);
      if (this.ingreso.literal == "B15") {
        this.ingreso.ncf = this.medicoSelected[0].compGuberAsig;
        this.ingreso.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.ingreso.fechaNCF = new Date();
      } else {
        this.ingreso.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.ingreso.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.ingreso.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fixTime() {
      this.ingreso.fechaProceso = this.formatDate(this.ingreso.fechaProceso);
      this.ingreso.fechaEfectivo = this.formatDate(this.ingreso.fechaEfectivo);
      this.ingreso.fechaVence = this.formatDate(this.ingreso.fechaVence);
    },

    async loadIngreso(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getIngreso(id);
        this.ingreso = data;
        this.estado = this.ingreso.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.ingreso.userMod = this.$store.state.user.usuario;
          await updateIngreso(this.$route.params.id, this.ingreso);
          this.$router.push("/ingresos");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      this.toggleAlert();
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteIngreso(this.$route.params.id);
          this.$router.push("/ingresos");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadIngreso(this.$route.params.id);
    }
  },
});
