
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Facturaa } from "@/interfaces/Facturaa";
import {
  deleteFacturaa,
  getFacturaa,
  updateFacturaa,
} from "@/services/cruzjiminiancj/FacturaaService";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import numeral from "numeral";
import moment from "moment";
import Pusher from "pusher-js";

export default defineComponent({
  name: "facturaa-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentEdition: {} as any,
      showInComentary: "",
      showModal: false,
      showModal2: false,
      showDelete: false,
      currentActivity: "",
      showAlert: false,
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      facturaa: {} as Facturaa,
      estado: {} as object,
    };
  },
  methods: {
    cancelar() {
      if (typeof this.$route.params.id === "string") {
        this.loadFacturaa(this.$route.params.id);
      }
      this.showModalMethod();
    },

    actualizar() {
      this.handleUpdate();
      this.showModalMethod();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    showModalMethod2() {
      this.showModal2 = !this.showModal2;
    },

    diffDate(dateValue: Date) {
      var NowMoment = moment().format("DD/MM/YYYY");
      // var Date = "2022-06-07";
      var Date = dateValue;

      return moment().diff(moment(Date), "days");
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },
    changeStatus() {
      // alert("Change...");
      this.currentActivity = this.facturaa.status;
      this.addActivity();
    },
    deleteService(it: any, user: string) {
      if (user == this.$store.state.user.usuario) {
        if (confirm("Está Seguro que desea Eliminar Esta Actividad?")) {
          this.facturaa.actividad.splice(it, 1);
          this.handleUpdate();
        }
      } else {
        alert("No puede Eliminar una Actividad de Otro Usuario");
      }
    },

    editService(index: number, actividad: any) {
      if (actividad.user == this.$store.state.user.usuario) {
        this.currentEdition = actividad;
        this.showModalMethod();
        // this.facturaa.actividad.splice(it, 1);
        // this.handleUpdate();
      } else {
        this.currentEdition = actividad;
        this.showModalMethod2();
      }
    },

    showDetails(index: number, actividad: any) {
      this.currentEdition = actividad;
      this.showModalMethod2();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentActivity !== "") {
        this.facturaa.actividad.push({
          description: this.currentActivity,
          date: new Date(),
          user: this.$store.state.user.usuario,
          detalles: "",
        });
        this.currentActivity = "";
        await this.handleUpdate();
      }
      document.getElementById("currentActivity").focus();
    },
    
    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },
    getARS(id_ars: string) {
      switch (id_ars) {
        case "5":
          return "ARS CMD";
        case "14":
          return "MAPFRE SALUD ARS, S.A.";
        case "8":
          return "FUTURO";
        case "12":
          return "META SALUD";
        case "23":
          return "YUNEN";
        case "17":
          return "RESERVAS";
        case "13":
          return "MONUMENTAL";
        case "4":
          return "ASEMAP";
        case "1":
          return "ARS APS S A";
        case "21":
          return "SIMAG";
        case "16":
          return "RENACER";
        case "59":
          return "GRUPO MEDICO ASOCIADO";
        case "24":
          return "PRIMERA  ARS DE HUMANO";
        case "22":
          return "UNIVERSAL";
        case "29":
          return "ALBA GAS S.R.L.";
        case "10":
          return "HUMANO SEGUROS";
        case "20":
          return "SENASA CONTRIBUTIVO";
        case "61":
          return "SENASA SUBSIDIADO";
        case "18":
          return "SEMMA";
        case "65":
          return "IDOPPRIL";
        default:
          return "ARS Descripcion";
      }
    },
    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async facturaar() {
      this.facturaa.cantPrefacturaa = 0;
      this.facturaa.cantFacturaa = 1;
      this.facturaa.credito = 0;
      this.facturaa.debito = 0;
      this.facturaa.subTipo = "Facturaa";
      this.facturaa.facturaa = this.facturaa.prefacturaa;
      this.facturaa.prefacturaa = 0;
      await this.selectCliente(this.facturaa.descCliente);
      this.facturaa.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );
      await this.selectMedico(this.facturaa.medico);
      if (this.facturaa.literal == "B15") {
        this.facturaa.ncf = this.medicoSelected[0].compGuberAsig;
        this.facturaa.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.facturaa.fechaNCF = new Date();
      } else {
        this.facturaa.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.facturaa.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.facturaa.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(4, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    fixTime() {
      this.facturaa.fecha = this.formatDate2(this.facturaa.fecha);
    },

    async loadFacturaa(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getFacturaa(id);
        this.facturaa = data;
        this.estado = this.facturaa.estado;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async loadFacturaa2(id: string) {
      try {
        const { data } = await getFacturaa(id);
        this.facturaa = data;
        this.estado = this.facturaa.estado;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
    },

    async handleUpdate() {
      // this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.facturaa.userMod = this.$store.state.user.usuario;
          await updateFacturaa(this.$route.params.id, this.facturaa);
          this.addMessage();
          // this.$router.push("/facturaas");
        }
      } catch (error) {
        //console.error(error);
      }
      // this.toggleLoading();
      // this.toggleAlert();
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          await deleteFacturaa(this.$route.params.id);
          this.addMessage();
          this.$router.push("/facturaas");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("ec64cab5b5fa0b45d374", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        if (typeof this.$route.params.id === "string") {
          this.loadFacturaa2(this.$route.params.id);
        }
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },
  },
  mounted() {
    this.showDeleteMethod();
    if (typeof this.$route.params.id === "string") {
      this.loadFacturaa(this.$route.params.id);
    }
    this.pusherSubscribe();
  },
});
