
const filters = ["Todos", "Power User", "Administrador", "Agente", "Encargado", "Especialista", "Encargado de Cuentas Médicas","Facturador", "Médico Especialista"];

export default {
  props: ["filterUsuarios", "search", "filteredUsuarios"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },
};
