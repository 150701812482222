
import Filters from "@/components/Especialista/FiltersComponent.vue";
import ListadoComponent from "@/components/Especialista/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Especialista } from "@/interfaces/Especialista";
import { getEspecialistas } from "@/services/cruzjiminiancj/Especialista";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      showSubmenu: false,
      criterio: {} as any,
      navMenuClas2: ["menu-navegacion2"],
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      especialistas: [] as Especialista[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleSubmenu() {
      this.showSubmenu = !this.showSubmenu;
    },
    filtrarEsp() {
      this.especialistas = this.especialistasFiltradas.filter(
        (especialista: Especialista) => {
          return especialista.especialidad
            .toLowerCase()
            .includes(this.criterio.especialidad.toLowerCase());
        }
      );
    },

    hamburger(texto: string) {
      switch (texto) {
        case "Especialistas":
          this.$router.push("/especialistas");
          break;
        case "Llamadas":
          this.$router.push("/llamadas");
          break;
        case "Productos":
          this.$router.push("/productos");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      // if (this.navMenuClas.length == 1) {
      //   this.navMenuClas.push("spread");
      //   this.desplegar = "fas fa-caret-up";
      // } else {
      //   this.navMenuClas.pop();
      //   this.desplegar = "fas fa-caret-down";
      // }
    },
    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadEspecialistas2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterEspecialistas(catName: string) {
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
        this.especialistasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.especialistas = this.especialistas.filter(
          (especialista: Especialista) => {
            return especialista.nombre === catName;
          }
        );
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
        this.especialistasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.especialistas = this.especialistas.filter(
          (especialista: Especialista) => {
            return especialista.nombre
              .toLowerCase()
              .includes(term.toLowerCase());
          }
        );
      }
      this.toggleLoading();
    },

    async loadEspecialistas() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
        this.especialistasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadEspecialistas2() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
        this.especialistasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadEspecialistas();
    // this.pusherSubscribe();
  },
};
