import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://server.cruzjiminian.com/api/",
  // baseURL: "http://172.168.10.187:3000/api",
  headers: {
    "Content-Type": "application/json",
  },
});
export default axiosInstance;
