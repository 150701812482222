
import Filters from "@/components/Producto/FiltersComponent.vue";
import ListadoComponent from "@/components/Producto/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Producto } from "@/interfaces/Producto";
import { getProductos } from "@/services/cruzjiminiancj/Producto";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      buscar: "",
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      productos: [] as Producto[],
      str: "",
      type: "",
    };
  },

  methods: {
    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadProductos2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterProductos(catName: string) {
      try {
        const res = await getProductos();
        this.productos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.productos = this.productos.filter((producto: Producto) => {
          return producto.almacn === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getProductos();
        this.productos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.productos = this.productos.filter((producto: Producto) => {
          return producto.desc.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadProductos() {
      this.toggleLoading();
      try {
        const res = await getProductos();
        this.productos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadProductos2() {
      this.toggleLoading();
      try {
        const res = await getProductos();
        this.productos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadProductos();
    this.pusherSubscribe();
  },
};
