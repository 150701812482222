
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Reporte } from "@/interfaces/Reporte";
import { getReportes } from "@/services/cruzjiminiancj/ReporteService";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import { getUsuarios } from "@/services/cruzjiminiancj/UsuarioService";
import { Usuario } from "@/interfaces/Usuario";
import {
  createReporte,
  eliminateReportes,
  createReportea,
  getOneReporte,
} from "@/services/cruzjiminiancj/ReporteService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "reportes-form",
  components: {
    Navbar,
  },
  data() {
    return {
      usuarios: [] as Usuario[],
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      reportes: [] as Reporte[],
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      reporte: {} as Reporte,
      oneReporte: {} as Reporte,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.reporte.solicitante = this.$store.state.user.usuario;
    this.loadReportes();
    this.defFields();
    this.focus();
    this.loadUsuarios();
  },

  methods: {
    async loadUsuarios() {
      this.toggleLoading();
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    async loadReportes() {
      this.toggleLoading();
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcReporte() {
      (this.reporte.retension = this.reporte.bruto * 0.1),
        (this.reporte.neto = this.reporte.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    defFields() {
      this.reporte.status = "Reportado";
      this.reporte.no = 1;
    },

    async loadOneReporte() {
      try {
        const res = await getOneReporte();
        this.oneReporte = res.data;
        this.one = this.oneReporte[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.reporte.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveReportea() {
      await this.loadOneReporte();
      try {
        const res = await createReportea(this.empleado);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveReporte() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneReporte();
          this.oneReporte = res.data;
          this.one = this.oneReporte[0];
          this.nextNo = this.one.no + 1;
          this.reporte.no = this.nextNo;
          this.reporte.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.reporte.userReg = this.$store.state.user.usuario;
        const res = await createReporte(this.reporte);
        // this.$router.push("/reportes/");
      } catch (error) {
        // // console.error(error);
      }
      await this.definingFields();
      await this.defFields();
      await this.addMessage();
      this.toggleAlert();
      await this.toggleLoading();
      document.getElementById("description").focus();
    },

    async deleteAllReportes() {
      try {
        const res = await eliminateReportes(this.reporte);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.reporte.description = "";
      this.reporte.type = "";
      this.reporte.solicitado = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("description").focus();
    },
  },
});
