
import numeral from "numeral";
import moment from "moment";
import { updateOne } from "@/services/cruzjiminiancj/FacturaService";
import { Factura } from "@/interfaces/Factura";
import { updateFactura } from "@/services/cruzjiminiancj/FacturaService";
import { createMensaje } from "@/services/cruzjiminiancj/ChatService";
import { GetByArs } from "@/services/cruzjiminiancj/FacturaService";

export default {
  props: ["facturas"],
  data() {
    return {
      currentActivity: "",
      data: false,
      totales: {},
      factura: {} as Factura,
    };
  },
  methods: {
    // async filterFacturas() {
    //   alert("Filtering");
    //   this.toggleLoading();
    //   try {
    //     const res = await GetByArs(this.$store.state.user);
    //     this.$store.state.user.facturas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   if (this.$store.state.user.status !== "Todos") {
    //     this.$store.state.user.facturas = this.$store.state.user.facturas.filter(
    //       (factura: Factura) => {
    //         return factura.status === this.$store.state.user.status;
    //       }
    //     );
    //   }
    //   this.toggleLoading();
    // },

    // deleteService(it: any) {
    //     this.$store.state.user.facturas.splice(it, 1);
    //     // this.handleUpdate();
    // },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    async moveStatus(factura: Factura, it: any) {
      this.factura = factura;
      switch (this.$store.state.user.status) {
        case "1 - Recibido por Auditoría Interna":
          this.currentActivity = "2 - Verificado por Auditoría Interna";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "2 - Verificado por Auditoría Interna":
          this.currentActivity = "3 - Verificado por Auditoría Externa";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "3 - Verificado por Auditoría Externa":
          this.currentActivity = "4 - Recibido por Reclamaciones Médicas";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "4 - Recibido por Reclamaciones Médicas":
          this.currentActivity = "5 - Verificado por Reclamaciones Médicas";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "5 - Verificado por Reclamaciones Médicas":
          this.currentActivity = "6 - Cargado a Lote";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "6 - Cargado a Lote":
          this.currentActivity = "7 - Enviado a la Aseguradora";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
      }
    },
    async moveStatus2(factura: Factura, it: any) {
      this.factura = factura;
      switch (this.$store.state.user.status) {
        case "1 - Recibido por Auditoría Interna":
          this.currentActivity = "2 - Verificado por Auditoría Interna";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "2 - Verificado por Auditoría Interna":
          this.currentActivity = "3 - Verificado por Auditoría Externa";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "3 - Verificado por Auditoría Externa":
          this.currentActivity = "4 - Recibido por Reclamaciones Médicas";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "4 - Recibido por Reclamaciones Médicas":
          this.currentActivity = "6 - Cargado a Lote";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "5 - Verificado por Reclamaciones Médicas":
          this.currentActivity = "6 - Cargado a Lote";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
        case "6 - Cargado a Lote":
          this.currentActivity = "7 - Enviado a la Aseguradora";
          this.factura.actividad.push({
            description: this.currentActivity,
            date: new Date(),
            user: this.$store.state.user.usuario,
            detalles: "",
          });
          this.factura.status = this.currentActivity;
          this.factura.userMod = this.$store.state.user.usuario;
          await updateFactura(this.factura._id, this.factura);
          this.$store.state.user.facturas.splice(it, 1);
          this.valorTotal();
          // this.filterFacturas();
          this.addMessage();
          break;
      }
    },

    diffDate(dateValue: Date) {
      var NowMoment = moment().format("DD/MM/YYYY");
      // var Date = "2022-06-07";
      var Date = dateValue;

      return moment().diff(moment(Date), "days");
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },

    toColorDate(type: string) {
      if (type >= "6") {
        return "valor1";
      } else if (type >= "5") {
        return "valor2";
      } else if (type >= "4") {
        return "valor3";
      } else if (type >= "3") {
        return "valor4";
      } else if (type >= "2") {
        return "valor5";
      } else if (type >= "1") {
        return "valor6";
      } else if (type >= "0") {
        return "valor7";
      }
    },

    toColorNumber(type: number) {
      if (type >= 500000) {
        return "valor1";
      } else if (type >= 350000) {
        return "valor2";
      } else if (type >= 225000) {
        return "valor3";
      } else if (type >= 63000) {
        return "valor4";
      } else if (type >= 28000) {
        return "valor5";
      } else if (type >= 7000) {
        return "valor6";
      } else if (type >= 0) {
        return "valor7";
      }
    },

    // toColorNumber(type: number) {
    //   if (type >= 6000) {
    //     return "valor1";
    //   } else if (type >= 5000) {
    //     return "valor1";
    //   } else if (type >= 4000) {
    //     return "valor5";
    //   } else if (type >= 3000) {
    //     return "valor5";
    //   } else if (type >= 2000) {
    //     return "valor5";
    //   } else if (type >= 1000) {
    //     return "valor7";
    //   } else if (type >= 0) {
    //     return "valor7";
    //   }
    // },

    getARS(id_ars: string) {
      switch (id_ars) {
        case "5":
          return "ARS CMD";
        case "14":
          return "MAPFRE SALUD ARS, S.A.";
        case "8":
          return "FUTURO";
        case "12":
          return "META SALUD";
        case "23":
          return "YUNEN";
        case "17":
          return "RESERVAS";
        case "13":
          return "MONUMENTAL";
        case "4":
          return "ASEMAP";
        case "1":
          return "ARS APS S A";
        case "21":
          return "SIMAG";
        case "16":
          return "RENACER";
        case "59":
          return "GRUPO MEDICO ASOCIADO";
        case "24":
          return "PRIMERA  ARS DE HUMANO";
        case "22":
          return "UNIVERSAL";
        case "29":
          return "ALBA GAS S.R.L.";
        case "10":
          return "HUMANO SEGUROS";
        case "20":
          return "SENASA CONTRIBUTIVO";
        case "61":
          return "SENASA SUBSIDIADO";
        case "18":
          return "SEMMA";
        case "65":
          return "IDOPPRIL";
        default:
          return "ARS Descripcion";
      }
    },

    async marcarListo(factura: Factura) {
      alert("Factura Lista.");
      try {
        const res = await updateOne(factura);
      } catch (error) {
        // console.error(error);
      }
    },

    toColor2(type: string) {
      if (type == "Error") {
        return "table-danger";
      } else if (type == "Función") {
        return "table-success";
      } else if (type == "Factura") {
        return "table-warning";
      }
    },
    valorTotal() {
      // alert("Calculanding");
      this.$store.state.user.totalesFacturas = this.$store.state.user.facturas.length;
      this.$store.state.user.totalesCobertura = this.$store.state.user.facturas.reduce(
        (accum: any, item: any) => accum + item.cobertura,
        0
      );
    },

    formatNumber(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0");
      }
    },

    // formatNumber2(value: number) {
    //   return numeral(value).format("0,0");
    // },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },
  },

  updated() {
    // this.valorTotal();
    // this.data = !this.data;
  },
};
