
import Filters from "@/components/Llamada/FiltersComponent.vue";
import ListadoComponent from "@/components/Llamada/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { Llamada } from "@/interfaces/Llamada";
import { Especialista } from "@/interfaces/Especialista";
import { getLlamadas, getServsByEsp } from "@/services/cruzjiminiancj/Llamada";
import { getEspecialistas } from "@/services/cruzjiminiancj/Especialista";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      showSubmenu: false,
      criterio: {} as any,
      navMenuClas2: ["menu-navegacion2"],
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      llamadas: [] as Llamada[],
      llamadasFiltradas: [] as Llamada[],
      especialistas: [] as Especialista[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleSubmenu() {
      this.showSubmenu = !this.showSubmenu;
    },

    async loadEspecialistas() {
      this.toggleLoading();
      try {
        const res = await getEspecialistas();
        this.especialistas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadServsByEsp() {
      this.toggleLoading();
      try {
        const res = await getServsByEsp(this.criterio);
        this.llamadas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    filtrarEsp() {
      this.llamadas = this.llamadasFiltradas.filter((llamada: Llamada) => {
        return llamada.especialidad
          .toLowerCase()
          .includes(this.criterio.especialidad.toLowerCase());
      });
    },

    fixTime() {
      if (this.criterio.desde) {
        this.criterio.desde = this.formatDateToFix(this.criterio.desde, false);
      }
    },

    formatDateToFix(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("yyyy-MM-DD");
    },

    hamburger(texto: string) {
      switch (texto) {
        case "Especialistas":
          this.$router.push("/especialistas");
          break;
        case "Llamadas":
          this.$router.push("/llamadas");
          break;
        case "Productos":
          this.$router.push("/productos");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
        this.desplegar = "fas fa-caret-up";
      } else {
        this.navMenuClas.pop();
        this.desplegar = "fas fa-caret-down";
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadLlamadas2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterLlamadas(catName: string) {
      try {
        const res = await getLlamadas(this.criterio);
        this.llamadas = res.data;
        this.llamadasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.llamadas = this.llamadas.filter((llamada: Llamada) => {
          return llamada.nombre === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getLlamadas(this.criterio);
        this.llamadas = res.data;
        this.llamadasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.llamadas = this.llamadas.filter((llamada: Llamada) => {
          return llamada.nombre.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadLlamadas() {
      this.toggleLoading();
      this.criterio.hasta = this.criterio.desde;
      try {
        const res = await getLlamadas(this.criterio);
        this.llamadas = res.data;
        this.llamadasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadLlamadas2() {
      this.toggleLoading();
      try {
        const res = await getLlamadas(this.criterio);
        this.llamadas = res.data;
        this.llamadasFiltradas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.criterio.desde = new Date();
    this.fixTime();
    this.loadLlamadas();
    this.loadEspecialistas();
    // this.pusherSubscribe();
  },
};
