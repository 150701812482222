
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Area } from "@/interfaces/Area";
import { getAreas } from "@/services/cruzjiminiancj/AreaService";
import {
  createArea,
  eliminateAreas,
  createAreaa,
  getOneArea,
} from "@/services/cruzjiminiancj/AreaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "areas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      areas: [] as Area[],
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      area: {} as Area,
      oneArea: {} as Area,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.loadAreas();
    this.defFields();
    this.focus();
  },

  methods: {
    async loadAreas() {
      this.toggleLoading();
      try {
        const res = await getAreas();
        this.areas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcArea() {
      (this.area.retension = this.area.bruto * 0.1),
        (this.area.neto = this.area.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    defFields() {
      this.area.estatus = "Pendiente";
    },

    async loadOneArea() {
      try {
        const res = await getOneArea();
        this.oneArea = res.data;
        this.one = this.oneArea[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.area.no = this.nextNo;
        this.area.principal = this.nextNo;
        this.area.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveAreaa() {
      await this.loadOneArea();
      try {
        const res = await createAreaa(this.empleado);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveArea() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneArea();
          this.oneArea = res.data;
          this.one = this.oneArea[0];
          this.nextNo = this.one.no + 1;
          this.area.no = this.nextNo;
          this.area.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.area.userReg = this.$store.state.user.usuario;
        const res = await createArea(this.area);
        // this.$router.push("/areas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      await this.defFields();
      document.getElementById("superior").focus();
      this.toggleAlert();
    },

    async deleteAllAreas() {
      try {
        const res = await eliminateAreas(this.area);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.area.description = "";
      this.area.type = "";
      this.area.superior = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("superior").focus();
    },
  },
});
