
import Filters from "@/components/Mejora/FiltersComponent.vue";
import ListadoComponent from "@/components/Mejora/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Mejora } from "@/interfaces/Mejora";
import { getMejoras } from "@/services/cruzjiminiancj/MejoraService";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      cargando: false,
      mejoras: [] as Mejora[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterMejoras(catName: string) {
      try {
        const res = await getMejoras();
        this.mejoras = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.mejoras = this.mejoras.filter((mejora: Mejora) => {
          return mejora.type === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getMejoras();
        this.mejoras = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.mejoras = this.mejoras.filter((mejora: Mejora) => {
          return mejora.title.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadMejoras() {
      this.toggleLoading();
      try {
        const res = await getMejoras();
        this.mejoras = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadMejoras();
  },
};
