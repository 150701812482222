import { Ingreso } from "@/interfaces/Ingreso";
import { AxiosResponse } from "axios";
import axios from "./axios";



export const getIngPerMed = async (): Promise<AxiosResponse<Ingreso[]>> =>
  await axios.get("/ingresos/getIngPerMed");

export const getIngPer = async (): Promise<AxiosResponse<Ingreso[]>> =>
  await axios.get("/ingresos/getIngPer");

export const getIngMed = async (): Promise<AxiosResponse<Ingreso[]>> =>
  await axios.get("/ingresos/getIngMed");

export const getIngresos = async (): Promise<AxiosResponse<Ingreso[]>> =>
  await axios.get("/ingresos/get");

export const getIngresosByUser = async (
  ingreso: Ingreso
): Promise<AxiosResponse<Ingreso>> =>
  await axios.post("/ingresos/GetByUser", ingreso);

export const getIngreso = async (id: string): Promise<AxiosResponse<Ingreso>> =>
  await axios.get(`/ingresos/GetById/${id}`);

export const getOneIngreso = async (): Promise<AxiosResponse<Ingreso>> =>
  await axios.get("/ingresos/GetOne");

export const createIngreso = async (ingreso: Ingreso): Promise<AxiosResponse> =>
  await axios.post("/ingresos/Save", ingreso);

export const createIngresoa = async (
  ingreso: Ingreso
): Promise<AxiosResponse> => await axios.post("/ingresos/Savea", ingreso);

export const getMejPend = async (): Promise<AxiosResponse<Ingreso[]>> =>
  await axios.get("/ingresos/getMejPend");

export const eliminateIngresos = async (
  ingreso: Ingreso
): Promise<AxiosResponse> => await axios.post("/ingresos/delete", ingreso);

export const updateOne = async (ingreso: Ingreso): Promise<AxiosResponse> =>
  await axios.post("/ingresos/updateOne", ingreso);

export const updateIngreso = async (
  id: string,
  newIngreso: Ingreso
): Promise<AxiosResponse<Ingreso>> =>
  await axios.put(`/ingresos/Update/${id}`, newIngreso);

export const deleteIngreso = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/ingresos/Delete/${id}`);
