
import numeral from "numeral";
import moment from "moment";
import { updateOne } from "@/services/cruzjiminiancj/AreaService";
import { Area } from "@/interfaces/Area";

export default {
  props: ["areas"],
  data() {
    return {
      data: false,
      totales: {
        areas: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    async marcarListo(area: Area) {
      alert("Area Lista.");
      try {
        const res = await updateOne(area);
      } catch (error) {
        // console.error(error);
      }
    },

    // toColor(type: string) {
    //   if (type == "Error") {
    //     return "table-danger";
    //   } else if (type == "Función") {
    //     return "table-success";
    //   } else if (type == "Area") {
    //     return "table-warning";
    //   }
    // },
    valorTotal() {
      this.totales.areas = this.areas.length;
      this.totales.prefactura = this.areas.reduce(
        (accum: any, item: any) => accum + item.prefactura,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
